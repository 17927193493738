import request from '@/utils/request'
// 轮播图
export  function links() {
    return request({
      url: 'api/api/links',
      method: 'post'
    })
  }
//合作伙伴
export  function partners() {
    return request({
      url: 'api/api/partners',
      method: 'post'
    })
  }
