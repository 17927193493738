import { render, staticRenderFns } from "./zhiyexuexiao.vue?vue&type=template&id=1ae9d238&scoped=true&"
import script from "./zhiyexuexiao.vue?vue&type=script&lang=js&"
export * from "./zhiyexuexiao.vue?vue&type=script&lang=js&"
import style0 from "./zhiyexuexiao.vue?vue&type=style&index=0&id=1ae9d238&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1ae9d238",
  null
  
)

export default component.exports