<template>
  <div class="lianxi">
    <div class="lianxi_img">
      <img :src="Image" alt="" class="img" />
    </div>
    <div class="lianxi_title">
      <!-- <h1 class="lianxi_title_h1">联系我们</h1> -->
      <!-- 三个联系方式 -->
      <ul class="tupian">
        <li>
          <div class="tupian_li_icon">
            <img src="~@/assets/yewutixi/联系我们_01.png" alt="" class="img" />
          </div>
          <div class="tupian_li_title">服务热线</div>
          <div class="tupian_li_jieshao">
            <div class="tupian_li_num">服务热线:&ensp;{{ lianxi.tel }}</div>
            <div class="tupian_li_youxiao">
              官方邮箱:&ensp;{{ lianxi.email }}
            </div>
          </div>
        </li>
        <li>
          <div class="tupian_li_icon">
            <img src="~@/assets/yewutixi/联系我们_02.png" alt="" class="img" />
          </div>
          <div class="tupian_li_title">{{ this.gongzhonghao1.filename }}</div>
          <div class="tupian_li_jieshao">
            <div class="icon">
              <img :src="this.gongzhonghao1.url" alt="" class="img" />
            </div>
          </div>
        </li>
        <li>
          <div class="tupian_li_icon">
            <img src="~@/assets/yewutixi/联系我们_04.png" alt="" class="img" />
          </div>
          <div class="tupian_li_title">{{ this.gongzhonghao2.filename }}</div>
          <div class="tupian_li_jieshao">
            <div class="icon">
              <img :src="this.gongzhonghao2.url" alt="" class="img" />
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div class="lianxi_map">
      <div class="lianxi_map_title_box">
        <!-- <h1 class="lianxi_map_title">山东学盟教服集团</h1> -->
		<h1 class="lianxi_map_title">
		  集团总部: 山东大学洪家楼校区1号研究生楼101-112室
		</h1>
        <h1 class="lianxi_map_title">
          济南校区: 济南市历城区洪家楼街道七里堡路27号历城科创金融大厦5楼
        </h1>
		<h1 class="lianxi_map_title">
		  潍坊校区: 潍坊市高新区福寿东街3417号泰恒大厦806室
		</h1>
		
        <h1 class="lianxi_map_title">
          烟台校区: 烟台市芝罘区锦华街万达金融中心B座1705号
        </h1>
        
        <h1 class="lianxi_map_title">
          泰安校区:泰安市岱岳区粥店街道堰西商业楼中科泰金中心二楼202室
        </h1>
        <h1 class="lianxi_map_title">
          淄博校区:淄博市张店区科苑街道华光路28号云龙国际B座13楼1301-6室
        </h1>
        <h1 class="lianxi_map_title">
          聊城校区:聊城市东昌府区花园南路119号化工学校校内4楼
        </h1>
		<h1 class="lianxi_map_title">
		 青岛校区:青岛市城阳区正阳中路77号鑫源世纪中心2号楼505A
		</h1>
		<h1 class="lianxi_map_title">
		  滨州校区:滨州市滨城区(万达南对面)建大清怡苑2号楼东501
		</h1>
		
		
        <!-- <h1 class="lianxi_map_title top">
          集团总部: 山东大学洪家楼校区1号研究生楼101-112室
        </h1> -->
      </div>
      <div class="lianxi_map_city">
        <baidu-map
          class="bm-view"
          :center="center"
          :zoom="zoom"
          inertial-dragging
          :scroll-wheel-zoom="true"
        >
          <bm-navigation anchor="BMAP_ANCHOR_TOP_LEFT"></bm-navigation>
          <bm-marker
            :position="center"
            :dragging="true"
            animation="BMAP_ANIMATION_BOUNCE"
          >
          </bm-marker>
        </baidu-map>
      </div>
    </div>
  </div>
</template>

<script>
import { concatme } from "@/api/lianxiwomen";
import { banner } from "@/api/intoxuemeng";
export default {
  data() {
    return {
      currentTab: 0,
      center: { lng: 116.404, lat: 39.915 },
      zoom: 15,
      lianxi: {},
      gongzhonghao1: {},
      gongzhonghao2: {},
      Image: "",
    };
  },
  created() {
    this.created();
  },
  methods: {
    handler({ BMap, map }) {
      // console.log(BMap, map);
      this.center.lng = 116.404;
      this.center.lat = 39.915;
      this.zoom = 15;
    },
    async created() {
      const lianxi = await concatme();
      const { accounts } = await concatme();
      this.lianxi = lianxi;
      this.gongzhonghao1 = accounts[0];
      this.gongzhonghao2 = accounts[1];
      this.center.lng = lianxi.lng;
      this.center.lat = lianxi.lat;
      const { concat } = await banner();
      this.Image = concat;
    },
  },
};
</script>

<style lang="scss" scoped>
.img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.lianxi {
  .lianxi_img {
    height: 320px;
    width: 100%;
    // background-color: #9b0e12;
  }
  //   联系我们
  .lianxi_title {
    width: 1400px;
    margin: 0 auto;
    margin-top: 50px;
    .lianxi_title_h1 {
      text-align: center;
      font-size: 30px;
      font-weight: 600;
      color: #30394d;
    }
    .tupian {
      margin: 0 auto;
      margin-top: 50px;
      width: 1165px;
      height: 330px;
      display: flex;
      justify-content: space-between;
      li {
        width: 30%;
        height: 110%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        // 图标
        .tupian_li_icon {
          // margin-bottom: 20px;
          width: 115px;
          height: 115px;
          background-color: #9b0e12;
        }
        // 服务热线
        .tupian_li_title {
          font-size: 26px;
          font-weight: 600;
          color: #30394d;
          position: relative;
          &::after {
            content: " ";
            position: absolute;
            top: 45px;
            left: 50%;
            transform: translateX(-50%);
            width: 70px;
            height: 5px;
            background-color: #9b0e12;
          }
        }
        .tupian_li_jieshao {
          display: flex;
          flex-direction: column;
          justify-content: center;
          line-height: 30px;
          font-size: 22px;
          height: 120px;
          .tupian_li_num{
            font-weight: 600;
          }
          .tupian_li_youxiao{
            font-weight: 600;
          }
          .icon {
            width: 110px;
            height: 110px;
            background-color: red;
          }
        }
      }
    }
  }
  //   地图
  .lianxi_map {
    width: 1400px;
    margin: 0 auto;
    margin-top: 100px;
    .lianxi_map_title_box {
      // width: 900px;
      width: fit-content;
      margin: 0 auto;
      .lianxi_map_title {
        // text-align: center;
        line-height: 35px;
        font-size: 20px;
        // font-weight: 600;
      }
    }

    .top {
      margin-top: 30px;
    }

    .lianxi_map_city {
      margin-top: 50px;
      width: 100%;
      height: 390px;
    }
  }
  .bm-view {
    width: 100%;
    height: 100%;
  }
}
</style>