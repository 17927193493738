<template>
  <div class="qyjs_box">
    <!-- 最上面的介绍 -->
    <div class="qyjs_box_top">
      <!-- 左侧盒子 -->
      <div class="qyjs_box_top_left">
        <!-- since 2012 -->
        <div class="qyjs_box_top_left_top">
          <span class="since">SINCE 2012</span>
          <span class="build"> 铺设升本学历路 搭建待岗职业桥</span>
        </div>

        <div class="qyjs_box_top_left_buttom">
          <div class="qyjs_box_top_left_buttom_left">
            <span class="qyjs_box_top_left_buttom_left_span">{{
              jieshao.years
            }}</span
            >+
            <div class="qyjs_box_top_left_buttom_left_div">
              深耕教育行业{{ jieshao.years }}+年
            </div>
          </div>
          <div class="qyjs_box_top_left_buttom_right">
            <div class="qyjs_box_top_left_buttom_left">
              <span class="qyjs_box_top_left_buttom_left_span"
                >{{ jieshao.teacher }} </span
              >+
              <div class="qyjs_box_top_left_buttom_left_div">
                {{ jieshao.teacher }}+学业规划导师
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 右侧盒子 -->
      <div class="qyjs_box_top_right">
        <div class="qyjs_box_top_right_top">走进学盟</div>
        <div class="qyjs_box_top_right_top_bottom">
          <div v-html="intro"></div>
        </div>
      </div>
    </div>

    <div class="qyjs_box_bottom">
      <img :src="jieshao.picture" alt="" class="img" />
    </div>
  </div>
</template>

<script>
import { xuemengintro } from "@/api/intoxuemeng";
export default {
  data() {
    return {
      jieshao: {},
      intro: "",
    };
  },
  created() {
    this.creat();
  },
  methods: {
    async creat() {
      const jieshao = await xuemengintro();
      this.jieshao = jieshao;
      this.intro= jieshao.intro.replace(/n/g, "&emsp;&emsp;");
    },
  },
};
</script>

<style lang="scss" scoped>
.img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.qyjs_box {
  // width: 1400px;
  // height: 100px;
  // background-color: #9b0e12;
  // margin: 0 auto;
  //
  .qyjs_box_top {
    margin-top: 50px;
    margin-bottom: 30px;
    display: flex;
    height: 280px;
    .qyjs_box_top_left {
      width: 270px;
      height: 100%;

      .qyjs_box_top_left_top {
        height: 60px;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: space-between;
        .since {
          font-size: 36px;
          height: 30px;
          color: #979ea7;
          margin-bottom: 14px;
        }
        .build {
          font-size: 14px;
          color: #313a4c;
        }
      }

      .qyjs_box_top_left_buttom {
        display: flex;
        margin-top: 32px;
        font-size: 12px;

        .qyjs_box_top_left_buttom_left {
          height: 75px;
          width: 115px;
          text-align: center;

          .qyjs_box_top_left_buttom_left_span {
            font-size: 42px;
            font-weight: 600;
            color: #9b0e12;
          }
          .qyjs_box_top_left_buttom_left_div {
            font-size: 12px;
          }
        }

        .qyjs_box_top_left_buttom_right {
          height: 75px;
          width: 115px;
          text-align: center;
          margin-left: 30px;

          .qyjs_box_top_left_buttom_left_span {
            font-size: 42px;
            font-weight: 600;
            color: #9b0e12;
          }
          .qyjs_box_top_left_buttom_left_div {
            font-size: 12px;
          }
        }
      }
    }
    .qyjs_box_top_right {
      margin-left: 90px;
      width: 1025px;
      // width: ;
      .qyjs_box_top_right_top {
        font-weight: 600;
        font-size: 28px;
        margin-bottom: 43px;
        color: #2f384a;
      }
      .qyjs_box_top_right_top_bottom {
        font-size: 18px;
        color: #30394b;
        line-height: 32px;
      }
    }
  }

  .qyjs_box_bottom {
    width: 1400px;
    height: 700px;
    background-color: #9b0e12;
    margin-bottom: 30px;
  }
}
</style>