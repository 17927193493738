<template>
  <div class="ywtx_box">
    <div class="ywtx_img">
      <img :src="Image" alt=""  class="img">
    </div>
    <div>
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import { banner } from "@/api/intoxuemeng";
export default {
  data() {
    return {
      Image: "",
    };
  },
  created() {
    this.create();
  },
  methods: {
    async create() {
       const { buss } = await banner();
      this.Image = buss;
    },
  },
};
</script>

<style lang="scss" scoped>
.img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.ywtx_box {
  .ywtx_img {
    height: 320px;
    width: 100%;
    // background-color: #9b0e12;
  }
}
</style>