
<template>
  <div class="xqgs_box">
    <div class="xqgs_box_item" v-for="item in xiaoqu" :key="item.id" @click="xiaoqutiaozhuan(item.id)" >
      <div class="xqgs_box_item_left">
        <img :src="item.image" alt="" class="img">
      </div>
      <div class="xqgs_box_item_right">
        <h1 class="xqgs_box_item_right_h1">
         {{item.title}}
        </h1>
        <div class="xqgs_box_item_right_jieshao"
        >
          &nbsp;&nbsp; {{item.intro}}
        </div>
        <span class="xqgs_box_item_right_time"> {{item.createtime | format}}</span>
      </div>
    </div>

    <div class="block">
      <!-- <el-pagination
        style="width: 200px"
        background
        layout="prev, pager, next"
        :total="20"
        :page-size="5"
      >
      </el-pagination> -->
    </div>
  </div>
</template>

<script>
import {newsstory} from '@/api/xinwenzhongxin'
export default {
  data(){
    return{
      xiaoqu:{}
    }
  },
  created(){
    this.create()
  },
  methods:{
    async create(){
      const xiaoqu  = await newsstory()
      this.xiaoqu = xiaoqu
      // console.log(this.xiaoqu);

    },
     xiaoqutiaozhuan(id) {
      this.$router.push({
        name: "xiaoquItem",
        params: {
          id: id,
        },
      });
    },
  }
};
</script>

<style lang="scss" scoped>
.img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.xqgs_box {
  .xqgs_box_item {
    display: flex;
    justify-content: space-between;
    margin-top: 55px;
    width: 1340px;
    height: 200px;
    .xqgs_box_item_left {
      width: 300px;
      height: 100%;
      background-color: #9b0e12;
    }
    .xqgs_box_item_right {
      width: 990px;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      // background-color: skyblue;
      .xqgs_box_item_right_h1 {
        font-size: 22px;
        font-weight: 600;
      }
      .xqgs_box_item_right_h1:hover {
        opacity: 0.4;
      }
      .xqgs_box_item_right_h1:active {
        opacity: 0.8;
      }
      .xqgs_box_item_right_jieshao {
        // margin-top: 30px;
        font-size: 17px;
        line-height: 30px;
      }
      .xqgs_box_item_right_time {
        font-size: 14px;
        color: #a1a8b1;
      }
    }
  }
  .block {
    // background-color: red;
    display: flex;
    justify-content: center;
    margin-top: 100px;
    /deep/.el-pager li{
      font-size: 15px;
    }
    /deep/ .btn-prev {
      width: 45px;
      height: 45px;
    }
    /deep/ .number {
      width: 45px;
      height: 45px;
      line-height: 47px;
      text-align: center;
    }
    /deep/ .el-pagination.is-background .el-pager li:not(.disabled):hover {
      color: #9b0e12;
    }
    /deep/.el-pagination.is-background .el-pager li:not(.disabled).active {
      background-color: #9b0e12dd;
      color: #fff;
    }
    /deep/ .btn-next {
      width: 45px;
      height: 45px;
    }
  }
}
</style>