<template>
    <div class="box">
    <img src="../../assets/国家开放大学页面1.jpg" alt="">
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
   .img{
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
//   body{
//     position: relative;
//   }
//   .box{
//     position: absolute;
//     top: 0;
//     // bottom: 0;
//   }
</style>