<template>
  <!-- 新闻页面 -->
  <div class="xxym">
    <!-- 上面的介绍 -->
    <div class="xxym_left_text">
      <!-- 左侧logo -->
      <div class="xxym_left_text_logo">
        <div class="logo_div">
          <img :src="school.image" alt="" class="img" />
        </div>
        <span class="logo_div_title">{{ school.name }}</span>
      </div>
      <!-- 富文本 -->
      <div class="xxym_right_text" v-html="school.content"></div>
    </div>
    <div class="xxym_left_bottom">
      <h1 class="xxym_h1">院校新闻</h1>

      <div class="xinwen">
        <div class="xinwen_left">
          <h1 class="title">学院动态</h1>
          <div class="pic">
            <img :src="dongtai.image" alt="" class="img" />
          </div>
          <ul class="xxym_ul">
            <li v-for="item in dongtai.news" :key="item.id">
              <div class="xxym_ul_text">{{ item.title }}</div>
              <div class="xxym_ul_titme">{{ item.createtime }}</div>
            </li>
          </ul>
        </div>
        <div class="xinwen_left">
          <h1 class="title">学院要闻</h1>
          <div class="pic">
            <img :src="yaowen.image" alt="" class="img" />
          </div>
          <ul class="xxym_ul">
            <li v-for="item in yaowen.news" :key="item.id">
              <div class="xxym_ul_text">{{ item.title }}</div>
              <div class="xxym_ul_titme">{{ item.createtime }}</div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  chengkaoschool,
  chengkaoschoolnewsd,
  chengkaoschoolnewsy,
} from "@/api/yewutixi";
export default {
  data() {
    return {
      id: 0,
      school: {},
      dongtai: {},
      yaowen: {},
    };
  },
  created() {
    this.create();
    this.id = this.$route.params.id;
    this.chengkaoschoolnewsd(this.$route.params.id);
    this.chengkaoschoolnewsy(this.$route.params.id);
    // this.parma();
    
  },
  mounted(){
    // console.log(this.school,'school');
    // this.parma()
  },
  methods: {
    async create() {
      const yuanxiao = await chengkaoschool();
      let result = yuanxiao.find((item) => {
        return item.id == this.id;
      });
      this.school = result;
      this.parma()
    },
    async chengkaoschoolnewsd(id) {
      this.dongtai = await chengkaoschoolnewsd(id);
    },
    async chengkaoschoolnewsy(id) {
      this.yaowen = await chengkaoschoolnewsy(id);
    },

    // 存储params传过来的数据 使页面刷新不丢失
   async parma() {
      var paramsData = await localStorage.getItem("paramsData");
      if (paramsData) {
        this.school= JSON.parse(localStorage.getItem("paramsData"));
      } else {
        this.school=localStorage.setItem("paramsData", JSON.stringify(this.school));
      }
    },
  },
  // 页面销毁之前
  beforeDestroy() {
    localStorage.removeItem("paramsData");
  },
};
</script>

<style lang="scss" scoped>
.img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.xxym {
  width: 1400px;

  margin: 0 auto;
  margin-top: 80px;

  .xxym_left_text {
    width: 100%;
    display: flex;
    .xxym_left_text_logo {
      width: 315px;
      height: 315px;
      // background-color: blue;
      border: 1px solid #e7e7e8;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;
      .logo_div {
        width: 195px;
        height: 195px;
        // background-color: yellow;
      }
      .logo_div_title {
        font-size: 30px;
        font-weight: 600;
      }
    }
    // 富文本
    .xxym_right_text {
      margin-left: 30px;
      flex: 1;
    }
  }
  .xxym_left_bottom {
    margin-top: 80px;
    width: 100%;
    .xxym_h1 {
      // margin-top: 100px;
      // margin-bottom: 90px;
      color: #30384d;
      font-size: 25px;
      font-weight: 600;
      text-align: center;
      position: relative;
      &::after {
        content: " ";
        position: absolute;
        top: 50px;
        left: 660px;
        width: 80px;
        height: 5px;
        background-color: #9b0e12;
      }
    }
    .xinwen {
      margin-top: 115px;
      width: 1400px;
      // height: 710px;
      // background-color: red;
      display: flex;
      justify-content: space-between;

      .xinwen_left {
        width: 670px;

        .title {
          margin-bottom: 30px;
          font-size: 25px;
        }
        .pic {
          margin-bottom: 30px;
          width: 100%;
          height: 305px;
          // background-color: skyblue;
        }
        .xxym_ul {
          width: 100%;

          li {
            height: 55px;
            font-size: 18px;
            border-bottom: 1px solid #e7e7e8;
            display: flex;
            justify-content: space-between;
            align-items: center;
          }
        }
      }
    }
  }
}
</style>