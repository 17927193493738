<template>
  <div class="city">
    <div class="city_box">
      <!-- 选择框 -->
      <div
        id="city"
        :class="{ city_hover: currentTab === index }"
        v-for="(item, index) in school"
        :key="item.id"
        @click="city_click(index, item.id)"
      >
        {{ item.name }}
      </div>
    </div>
    <!-- 下面变化的校区介绍 -->
    <keep-alive>
      <div class="city_content_box">
        <div class="city_content">
          {{ result.intro }}
        </div>
        <!-- 下面放照片的盒子 -->
        <div class="city_content_box_bottom">
          <div class="city_content_box_bottom_img_box" v-for=" (item,index) in result.image " :key="index">
            <img :src="item" alt="" class="img">
          </div>
        </div>
      </div>
    </keep-alive>
    <!-- 右侧地图 -->
    <div class="city_map">
      <baidu-map
        class="bm-view"
        :center="center"
        :zoom="zoom"
        inertial-dragging
        :scroll-wheel-zoom="true"
      >
        <bm-navigation anchor="BMAP_ANCHOR_TOP_LEFT"></bm-navigation>
        <!-- v-for="item in school" :key="item.id"
         :visible="item.id ===id" -->
        <bm-marker
          :position="center"
          :dragging="true"
          animation="BMAP_ANIMATION_BOUNCE"
        >
         <bm-label :content="result.name" :labelStyle="{color: 'red', fontSize : '12px'}" :offset="{width: -15, height: 30}"/>
        </bm-marker>
      </baidu-map>
    </div>
  </div>
</template>

<script>
import { school } from "@/api/home";
export default {
  data() {
    return {
      currentTab: 0,
      center: { lng: 0, lat: 0 },
      // center1: { lng: 116.404, lat: 39.915 },
      zoom: 15,
      school: {},
      result: {},
      id: 3,
    };
  },
  created() {
    this.create();
  },
  
  methods: {
    city_click(index, id) {
      this.res(id);
      this.currentTab = index;
    },

    res(id) {
      let result = this.school.find((item) => {
        return item.id == id;
      });
      this.result = result;
      // console.log(this.result,'res');
      this.center.lat = result.lat;
      this.center.lng = result.lng;
    },

    async create() {
      const sch = await school();
      this.school = sch;
      this.res(this.id);
    },
  },
};
</script>

<style lang="scss" scoped>
.img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.city {
  width: 1400px;
  margin: 0 auto;
  position: relative;
  .city_box {
    display: flex;
    width: 800px;
    #city {
      width: 100px;
      height: 66px;
      color: #0c1429;
      background-color: #eee;
      font-size: 19px;
      text-align: center;
      line-height: 66px;
      margin-right: 10px;
      border: 1px solid #d9d9d9;
      box-sizing: border-box;
    }
    #city:hover {
      color: #fff !important;
      background-color: #9b0e12 !important;
      border: 1px solid #9b0e12 !important;
    }
    .city_hover {
      color: #fff !important;
      opacity: 0.9;
      background-color: #9b0e12 !important;
      border: 1px solid #9b0e12 !important;
    }
  }
  //
  .city_content_box {
  //  height: 270px;
    width: 760px;
    // background-color: skyblue;
     border: 1px solid #d9d9d9;
  }
  .city_content {
    text-indent: 2em;
    padding: 20px;
    box-sizing: border-box;
    height: 140px;
    // width: 760px;
    font-size: 17px;
    line-height: 30px;
    margin-top: 14px;
   
    overflow: hidden; // 隐藏超出部分
    overflow-y: scroll; // 设置纵向滚动效果，x为横向
  }
  .city_content_box_bottom{
     height: 140px;
    // width: 760px;
    // background-color: red;
    display: flex;
    justify-content: space-between;
    .city_content_box_bottom_img_box{
      width: 240px;
      // height: 140px;
    }
  }
  .city_map {
    position: absolute;
    top: 0px;
    right: 0px;
    height: 100%;
    width: 600px;
  }
  .bm-view {
    width: 100%;
    height: 100%;
  }
}

::-webkit-scrollbar {
  width: 5px;
}

/*定义滚动条轨道

内阴影+圆角*/

::-webkit-scrollbar-track {
  // -webkit-box-shadow:inset 0 0 6px rgba(210,210,210,0.1);

  border-radius: 10px;

  background-color: #f5f5f5;
}

/*定义滑块

内阴影+圆角*/

::-webkit-scrollbar-thumb {
  border-radius: 10px;

  background-color: rgba(210, 210, 210, 0.999);
}
</style>