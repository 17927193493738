<template>
  <div class="gxgh_box">
    <!-- <h1 class="gxgh_box_h1">
      高校关怀
    </h1> -->
    <div class="gxgh_box_div">
      {{ gaoxiao.intro }}
    </div>
    <ul class="gxgh_box_ul">
      <li v-for="(item, index) in gaoxiao.url" :key="index">
        <img :src="item" alt=""  class="img"/>
      </li>
      <!-- <li ></li> -->
    </ul>
  </div>
</template>

<script>
import { schoolguanhuai } from "@/api/intoxuemeng";
export default {
  data() {
    return {
      gaoxiao: {},
    };
  },
  created() {
    this.creat();
  },
  methods: {
    async creat() {
      const gaoxiao = await schoolguanhuai();
      this.gaoxiao = gaoxiao;
    },
  },
};
</script>

<style lang="scss" scoped>
.img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.gxgh_box {
  width: 100%;
  color: #30394d;
  // .gxgh_box_h1{
  //   margin-top: 50px;
  //   margin-bottom: 40px;
  //   text-align: center;
  //   font-size: 30px;
  //   font-weight: 600;
  // }
  .gxgh_box_div {
    // margin-top: 50px;
    width: 755px;
    margin: 0 auto;
    margin-top: 50px;
    text-align: center;
    font-size: 20px;
  }
  .gxgh_box_ul {
    margin-top: 50px;
    width: 1400px;
    // height: 960px;
    // background-color: skyblue;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    li {
      width: 450px;
      height: 310px;
      // margin: 10px;
      margin-top: 10px;
      background-color: #9b0e12;
    }
  }
}
</style>