<template>
  <div class="hydt_box">
    <div class="hydt_box_item" v-for="item in hangye" :key="item.id" @click="hydttiaozhuan(item.id)">
      <div class="hydt_box_item_left">
        {{item.title}}
      </div>
      <div class="hydt_box_item_right">
        {{item.createtime}}
      </div>
    </div>

    <div class="block">
      <!-- <el-pagination
        style="width: 200px"
        background
        layout="prev, pager, next"
        :total="20"
        :page-size="5"
      >
      </el-pagination> -->
    </div>
  </div>
</template>

<script>
import { newstrade } from "@/api/xinwenzhongxin";
export default {
  data() {
    return {
      hangye:{}
    };
  },
  created() {
    this.create();
  },
  methods: {
    async create() {
      const hangye = await newstrade();
      this.hangye = hangye
      // console.log(this.hangye);

    },
    hydttiaozhuan(id){
       this.$router.push({
        name: "hangyeItem",
        params: {
          id: id,
        },
      });
    }
  },
};
</script>

<style lang="scss" scoped>
.hydt_box {
  .hydt_box_item {
    display: flex;
    justify-content: space-around;
    // height: 80px;
    width: 100%;
    line-height: 60px;
    font-size: 18px;
    // background-color: #9b0e12;
    // margin-left: 30px;

    border-bottom: 1px solid #e3e3e3;

    .hydt_box_item_left {
      width: 85%;
      padding-left: 30px;
    }
    .hydt_box_item_right {
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 1;
      color: #a1a7b0;
      font-size: 14px;
    }
  }
  .hydt_box_item:hover {
    color: #9b0e12;
  }

  .block {
    // background-color: red;
    display: flex;
    justify-content: center;
    margin-top: 100px;
    /deep/.el-pager li {
      font-size: 15px;
    }
    /deep/ .btn-prev {
      width: 45px;
      height: 45px;
    }
    /deep/ .number {
      width: 45px;
      height: 45px;
      line-height: 47px;
      text-align: center;
    }
    /deep/ .el-pagination.is-background .el-pager li:not(.disabled):hover {
      color: #9b0e12;
    }
    /deep/.el-pagination.is-background .el-pager li:not(.disabled).active {
      background-color: #9b0e12dd;
      color: #fff;
    }
    /deep/ .btn-next {
      width: 45px;
      height: 45px;
    }
  }
}
</style>