<template>
  <div class="zsb_box">
    <!-- 专升本简介 -->
    <div class="jianjie">
      <h1 class="zsb_h1">专升本简介</h1>
      <div class="zsb_jieshao">
        {{ chengren.intro }}
      </div>
    </div>
    <div class="peixun">
      <h1 class="zsb_h1_2">培训优势</h1>
      <ul class="zsb_ul">
        <li v-for="(item, index) in chengren.peixun" :key="item.id">
          <div class="zsb_div">0{{ index + 1 }}</div>
          <span class="zsb_span">{{ item.title }}</span>
          <div class="zsb_div2">
            {{ item.content }}
          </div>
        </li>
      </ul>
    </div>
    <div class="zhengshu">
      <!-- 四个圆 -->
      <div class="zsb_kechengtixi">
        <h1 class="zsb_kechengtixi_h1">证书优势</h1>
        <ul class="zsb_kechengtixi_ul">
          <li v-for="item in chengren.zhengshu" :key="item.id">
            <div class="zsb_kechengtixi_ul_div">
              {{ item.title }}
            </div>
            <div class="zsb_kechengtixi_ul_jieshao">
              {{ item.content }}
            </div>
          </li>
        </ul>
      </div>
    </div>

    <div class="zsb_biyezhengshu">
      <div class="zsb_biyezhengshu_left">
        <div class="icon">
          <img src="~@/assets/引号.png" alt="" class="img"/>
        </div>
        <div class="text">统招专升本毕业证书</div>
      </div>
      <ul class="zsb_biyezhengshu_right">
        <li v-for="(item, index) in chengren.cers" :key="index">
          <img :src="item" class="img" alt="" />
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { business9 } from "@/api/yewutixi";
export default {
  data() {
    return {
      chengren: {},
    };
  },
  created() {
    this.create();
  },
  methods: {
    async create() {
      const chengren = await business9();
      this.chengren = chengren;
      // console.log(chengren);
    },
  },
};
</script>

<style lang="scss" scoped>
.img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
html {
  position: relative;
}
.zsb_box {
  .jianjie {
    width: 1400px;
    margin: 0 auto;
    .zsb_h1 {
      margin-top: 50px;
      margin-bottom: 50px;
      color: #30384d;
      font-size: 25px;
      font-weight: 600;
      text-align: center;
      position: relative;
      &::after {
        content: " ";
        position: absolute;
        top: 50px;
        left: 50%;
        margin-left: -40px;
        width: 80px;
        height: 5px;
        background-color: #9b0e12;
      }
    }
    .zsb_jieshao {
      text-indent: 2em;
      margin-bottom: 50px;
      font-size: 20px;
      line-height: 40px;
    }
  }
  .peixun {
    width: 1400px;
    margin: 0 auto;
    .zsb_h1_2 {
      margin-top: 50px;
      // 因为下面脱标了  所以高度要给它加上
      margin-bottom: 50px;
      color: #30384d;
      font-size: 25px;
      font-weight: 600;
      text-align: center;
      position: relative;
      &::after {
        content: " ";
        position: absolute;
        top: 50px;
        left: 50%;
        margin-left: -40px;
        width: 80px;
        height: 5px;
        background-color: #9b0e12;
      }
    }
    .zsb_ul {
      // margin-bottom: 850px;
      width: 100%;
      // height: 750px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-content: space-between;
      li {
        width: 690px;
        height: 365px;
        background-color: #fcfafc;
        display: flex;
        flex-direction: column;
        // align-content: center;
        align-items: center;
        justify-content: center;
        .zsb_div {
          margin-bottom: 35px;
          width: 90px;
          height: 90px;
          background-color: #9b0e12;
          text-align: center;
          line-height: 90px;
          font-size: 33px;
          font-weight: 600;
          color: #fff;
        }
        .zsb_span {
          margin-bottom: 35px;
          color: #30384d;
          font-size: 19px;
          font-weight: 600;
        }
        .zsb_div2 {
          text-indent: 2em;
          width: 600px;
          font-size: 17px;
          color: #30384d;
          line-height: 28px;
          text-align: center;
        }
      }
    }
  }
  .zhengshu {
    margin-top: 50px;
    position: relative;
    height: 600px;
    .zsb_kechengtixi {
      position: absolute;
      left: 0;
      // top: 1220px;
      width: 100%;
      height: 100%;
      background-color: #9b0e12;
      .zsb_kechengtixi_h1 {
        margin-top: 50px;
        // 因为下面脱标了  所以高度要给它加上
        margin-bottom: 50px;
        color: #fff;
        font-size: 25px;
        font-weight: 600;
        text-align: center;
        position: relative;
        &::after {
          content: " ";
          position: absolute;
          top: 50px;
          left: 50%;
          margin-left: -40px;
          width: 80px;
          height: 5px;
          background-color: #fff;
        }
      }
      .zsb_kechengtixi_ul {
        width: 1400px;
        height: 450px;
        margin: 0 auto;
        display: flex;
        li {
          width: 25%;
          height: 90%;
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          align-items: center;
          .zsb_kechengtixi_ul_div {
            width: 175px;
            height: 175px;
            border-radius: 50%;
            border: 3px solid #fff;
            text-align: center;
            line-height: 175px;
            color: #fff;
            font-size: 25px;
            font-weight: 600;
          }
          .zsb_kechengtixi_ul_jieshao {
            text-indent: 2em;
            width: 215px;
            color: #fff;
            text-align: center;
            font-size: 17px;
          }
        }
      }
    }
  }

  .zsb_biyezhengshu {
    margin: 0 auto;
    margin-top: 50px;
    width: 1400px;
    height: 330px;
    display: flex;
    justify-content: space-between;
    .zsb_biyezhengshu_left {
      width: 315px;
      background-color: #9b0e12;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      .icon {
        position: absolute;
        top: 70px;
        left: 70px;
        width: 30px;
      }
      .text {
        width: 150px;
        font-size: 30px;
        font-weight: 600;
        color: #fff;
      }
    }
    .zsb_biyezhengshu_right {
      // flex: ;
      width: 1000px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      li {
        width: 325px;
        height: 220px;
        background-color: #30384d;
         margin-right: 10px;
      }
    }
  }
}
</style>