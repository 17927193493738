
<template>
  <div class="jrdt_box">
    <div
      class="jrdt_box_item"
      v-for="item in jituan"
      :key="item.id"
      @click="jituandongtai(item.id)"
    >
      <div class="jrdt_box_item_left">
        <img :src="item.image" alt="" class="img">
      </div>
      <div class="jrdt_box_item_right">
        <h1 class="jrdt_box_item_right_h1">
          {{ item.title }}
        </h1>
        <div class="jrdt_box_item_right_jieshao">
          &nbsp;&nbsp; {{ item.intro }}
        </div>
        <span class="jrdt_box_item_right_time">
          {{ item.createtime | format }}</span
        >
      </div>
    </div>
    <div class="block"></div>
  </div>
</template>

<script>
import { newstrends } from "@/api/xinwenzhongxin";
export default {
  data() {
    return {
      jituan: {},
    };
  },
  created() {
    this.create();
  },
  methods: {
    async create() {
      const jituan = await newstrends();
      this.jituan = jituan;
      // console.log(jituan);
    },
    jituandongtai(id) {
      // 以为是可以接口传id  结果接口写的不行
      this.$router.push({
        name: "jituanItem",
        params: {
          id: id,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.jrdt_box {
  .jrdt_box_item {
    display: flex;
    justify-content: space-between;
    margin-top: 55px;
    width: 1340px;
    height: 200px;
    .jrdt_box_item_left {
      width: 300px;
      height: 100%;
      background-color: #9b0e12;
    }
    .jrdt_box_item_right {
      width: 990px;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      &:hover {
        .jrdt_box_item_right_h1 {
          opacity: 0.4;
        }
      }
      &:active {
        .jrdt_box_item_right_h1 {
          opacity: 0.8;
        }
      }
      // background-color: skyblue;
      .jrdt_box_item_right_h1 {
        font-size: 22px;
        font-weight: 600;
      }

      .jrdt_box_item_right_jieshao {
        // margin-top: 30px;
        font-size: 17px;
        line-height: 30px;
      }
      .jrdt_box_item_right_time {
        font-size: 14px;
        color: #a1a8b1;
      }
    }
  }
  .block {
    // background-color: red;
    display: flex;
    justify-content: center;
    margin-top: 100px;
    /deep/.el-pager li {
      font-size: 15px;
    }
    /deep/ .btn-prev {
      width: 45px;
      height: 45px;
    }
    /deep/ .number {
      width: 45px;
      height: 45px;
      line-height: 47px;
      text-align: center;
    }
    /deep/ .el-pagination.is-background .el-pager li:not(.disabled):hover {
      color: #9b0e12;
    }
    /deep/.el-pagination.is-background .el-pager li:not(.disabled).active {
      background-color: #9b0e12dd;
      color: #fff;
    }
    /deep/ .btn-next {
      width: 45px;
      height: 45px;
    }
  }
}
</style>