<template>
  <div class="ssfw">
    <div class="ssfw_div" v-for="item in  xuemeng" :key="item.id">
      <div class="ssfw_div_left">
        <h1 class="ssfw_div_h1">{{item.title}}</h1>
        <div class="ssfw_div_div">
          {{item.content}}
        </div>
      </div>
      <div class="ssfw_div_right">
        <img :src="item.nimage" alt="" class="img">
      </div>
    </div>

    <!-- 课程体系 -->
    <div class="ssfw_kctx">
      <h1>课程体系</h1>
      <div class="ssfw_kctx_bottom">
        <div class="ssfw_kctx_bottom_left">
          <img src="~@/assets/课程体系.png" alt="" class="img">
        </div>
       <div class="ssfw_kctx_bottom_right">
         <ul>
          <li>
            <span class="icon_yuan"></span>
            <div class="li_title">完善课程体系,优化课程结构;</div>
          </li>
          <li>
            <span class="icon_yuan"></span>
            <div class="li_title">根据考试大纲，独家研发学习备考视频和教材;</div>
          </li>
          <li>
            <span class="icon_yuan"></span>
            <div class="li_title">学盟教育学盟云辅导，学员随时随地在线学习;</div>
          </li>
          <li>
            <span class="icon_yuan"></span>
            <div class="li_title">学员专属学习群，金牌讲师直播+录播教学，随时答疑解惑;</div>
          </li>
        </ul>
       </div>
      </div>
    </div>
  </div>
</template>

<script>
import { teacher4srv} from "@/api/xuemengfuwu";
export default {
  data() {
    return {
      xuemeng:{},
    };
  },
  created() {
    this.create();
  },
  methods: {
    async create() {
      const xuemeng = await teacher4srv();
      this.xuemeng=xuemeng
      // console.log(xuemeng);
    },
  },
};
</script>

<style lang="scss" scoped>
.img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.ssfw {
  width: 100%;
  .ssfw_div {
    margin: 0 auto;
    margin-top: 50px;
    margin-bottom: 50px;
    width: 1100px;
    height: 405px;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &:nth-child(even) {
      /* 使用flexbox布局 */
      display: flex;
      /* 反转主轴方向，使左右子盒子交换位置 */
      flex-direction: row-reverse;
    }
    .ssfw_div_left {
      width: 550px;
      height: 95px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      // background-color: blue;
      .ssfw_div_h1 {
        font-size: 26px;
        font-weight: 600;
        color: #2f384a;
        position: relative;
        &:after {
          position: absolute;
          top: 40px;
          left: 0px;
          // display: block;
          width: 55px;
          height: 5px;
          // border-radius: 50%;
          background: #9b0e12;
          content: "";
        }
      }
      .ssfw_div_div {
        text-indent: 2em;
        margin-top: 30px;
        line-height: 30px;
        font-size: 17px;
        width: 100%;
      }
    }
    .ssfw_div_right {
      width: 405px;
      height: 100%;
      // background-color: #9b0e12;
    }
  }
  // 课程体系
  .ssfw_kctx {
    width: 1400px;
    margin:  0 auto;
    h1 {
      margin-bottom: 50px;
      color: #30384d;
      font-size: 40px;
      font-weight: 600;
      text-align: center;
    }
    .ssfw_kctx_bottom {
      width: 100%;
      flex: 1;
      // background-color: red;
      // height: 540px;
      // background-color: #fff;
      display: flex;
      justify-content: space-around;
      .ssfw_kctx_bottom_left {
        // width: 530px;
        // height: 530px;
      }
      .ssfw_kctx_bottom_right {
        flex: 1;
        margin-left: 100px;
        display: flex;
        align-items: center;
        ul{
          height: 250px;
          width: 100%;
          display: flex;
          flex-direction: column;
          li {
          flex: 1;
          display: flex;
          align-items: center;

          display: flex;
          .icon_yuan {
            margin-right: 15px;
            width: 13px;
            height: 13px;
            border: 3px solid #9b0e12;
            border-radius: 50%;
          }
          .li_title{
            font-size: 20px;
            font-weight: 600;
            color: #30384d;
          }
        }
        }
        
      }
    }
  }
}
</style>