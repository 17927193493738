<template>
  <div class="whln_box">
    <!-- 上面的盒子 -->
    <div class="whln_box_top">
      <ul class="whln_box_top_ui">
        <li class="whln_box_top_li">
          <!-- 图标 -->
          <div class="whln_box_top_li_icon">
            <img src="~@/assets/愿景.png" alt="">
          </div>
          <!-- 图标右侧文字 -->
          <div class="whln_box_top_li_title">愿景
            <span class="whln_box_top_li_ENGLISH">Vision</span>
          </div>
          <!-- 小字介绍 -->
          <div class="whln_box_top_li_jieshao">
            {{yuanjing.vision}}
          </div>
        </li>
         <li class="whln_box_top_li">
          <!-- 图标 -->
          <div class="whln_box_top_li_icon">
            <img src="~@/assets/使命.png" alt="">
          </div>
          <!-- 图标右侧文字 -->
          <div class="whln_box_top_li_title">使命
            <span class="whln_box_top_li_ENGLISH">mission</span>
          </div>
          <!-- 小字介绍 -->
          <div class="whln_box_top_li_jieshao">
            {{yuanjing.mission}}
          </div>
        </li>
         <li class="whln_box_top_li">
          <!-- 图标 -->
          <div class="whln_box_top_li_icon">
            <img src="~@/assets/理念.png" alt="">
          </div>
          <!-- 图标右侧文字 -->
          <div class="whln_box_top_li_title">理念
            <span class="whln_box_top_li_ENGLISH">idea</span>
          </div>
          <!-- 小字介绍 -->
          <div class="whln_box_top_li_jieshao">
            {{yuanjing.idea}}
          </div>
        </li>
         <li class="whln_box_top_li">
          <!-- 图标 -->
          <div class="whln_box_top_li_icon">
            <img src="~@/assets/价值观.png" alt="">
          </div>
          <!-- 图标右侧文字 -->
          <div class="whln_box_top_li_title">价值观
            <span class="whln_box_top_li_ENGLISH">values</span>
          </div>
          <!-- 小字介绍 -->
          <div class="whln_box_top_li_jieshao">
            {{yuanjing.values}}
          </div>
        </li>
      </ul>
    </div>

    <!-- 企业活动 -->
    <div class="whln_qyhd">
      <h1 class="whln_qyhd_h1">企业活动</h1>
      <ul class="whln_qyhd_ul">
        <li v-for="(item,index) in qiyehuodong" :key="index">
          <img :src="item" alt="" class="img">
        </li>
      </ul>
    </div>

     <!-- 团队风采 -->
    <div class="whln_qyhd">
      <h1 class="whln_qyhd_h1">团队风采</h1>
      <ul class="whln_qyhd_ul">
        <li v-for="(item,index) in tuandui" :key="index">
            <img :src="item" alt="" class="img">
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import {xuemengintro ,activity ,team } from '@/api/intoxuemeng'
export default {
   data(){
    return{
      yuanjing:{},
      qiyehuodong:{},
      tuandui:{}
    }
  },
  created(){
    this.creat()
  },
  methods:{
       async creat(){
          const jieshao = await xuemengintro()
          const qiyehuodong = await activity()
          const tuandui = await team()
          this.tuandui = tuandui.url
          // console.log(tuandui);
          this.yuanjing= jieshao
          this.qiyehuodong=qiyehuodong.url
       }
  }
 

};
</script>

<style lang="scss" scoped>
.img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.whln_box {
  margin-top: 50px;
  // 上面的盒子
  .whln_box_top {
    width: 100%px;
    height: 620px;
    background-color: #fff;
    border: 0.5px solid #c8ccd0;
    display: flex;
    justify-content: center;
    align-items: center;

    .whln_box_top_ui {
      width: 1000px;
      height: 400px;
      // background-color: skyblue;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .whln_box_top_li {
        // position: relative;
        // background-color: red;
        width: 50%;
        // background-color: yellow;
        // border: 1px solid #000;
        // 图标
        .whln_box_top_li_icon {
          // position: absolute;
          margin-top: 45px;
          margin-left: 22px;
          height: 55px;
          width: 55px;
        }
        // 图标右侧文字
        .whln_box_top_li_title {
          // position: absolute;
         margin-top:-50px;
          margin-left:100px;
          font-size: 26px;
          font-weight: 600;
          color: #30384d;
          .whln_box_top_li_ENGLISH{
            font-size:15px;
          }
        }
        // 小字介绍
        .whln_box_top_li_jieshao{
          // position: absolute;
         margin-top: 10px;
         margin-left: 100px;
          width: 330px;
          font-size: 16px;
          color: #31394e;
        }
      }
    }
  }

  // 企业活动
  .whln_qyhd{
    margin-top: 80px;
    margin-bottom: 50px;
    width: 100%;
    height: 550px;
    .whln_qyhd_h1{
      margin-bottom: 55px;
      text-align: center;
      color: #30394d;
      font-size: 30px;
      font-weight: 600;
    }
    .whln_qyhd_ul{
      height: 470px;
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-evenly;
      li{
        width: 340px;
        height: 228px;
        background-color: #9d0e12;
      }
    }
  }
}
</style>