<template>
  <div class="ywtx_div">
    <h1 class="ywtx_h1">业务体系</h1>
    <div class="ywtx_div_content" v-for="item in ywtx" :key="item.id">
      <!-- 左侧图片 -->
      <div class="ywtx_div_content_left">
        <img :src="item.image" alt="" class="img" />
      </div>
      <!-- 右侧盒子 -->
      <div class="ywtx_div_content_right">
        <!-- 标题 -->
        <div class="ywtx_div_content_right_title">
          <h1 class="ywtx_div_content_right_h1" @click="itemPush(item.id)">
            {{ item.name }}
          </h1>
          <span class="ywtx_div_content_right_span">{{ item.title }}</span>
        </div>

        <!-- 介绍 -->
        <div class="ywtx_div_content_right_jieshao">
          {{ item.intro }}
        </div>

        <!-- 跳转整体盒子 -->
        <div>
          <ul class="ywtx_div_content_right_router" v-if="item.type === 1">
            <li
              :class="item.jinzhi ===false ? 'jinzhi':''"
              v-for="item in type1"
              :key="item.id"
              @click="dianjitiaozhuan(item.id)"
            >
              <div class="ywtx_div_content_router_img">
                <img :src="item.url" alt="" class="img" />
              </div>
              <div class="ywtx_div_content_router_li_div">
                <span>{{ item.name }}</span>
                <div>了解详情 &rharu;</div>
              </div>
            </li>
          </ul>
          <ul class="ywtx_div_content_right_router" v-if="item.type === 2">
            <li
            :class="item.jinzhi ===false ? 'jinzhi':''"
              v-for="item in type2"
              :key="item.id"
              @click="dianjitiaozhuan(item.id)"
            >
              <div class="ywtx_div_content_router_img">
                <img :src="item.url" alt="" class="img" />
              </div>
              <div class="ywtx_div_content_router_li_div">
                <span>{{ item.name }}</span>
                <div>了解详情 &rharu;</div>
              </div>
            </li>
          </ul>
          <ul class="ywtx_div_content_right_router" v-if="item.type === 3">
            <li
            :class="item.jinzhi ===false ? 'jinzhi':''"
              v-for="item in type3"
              :key="item.id"
              @click="dianjitiaozhuan(item.id)"
            >
              <div class="ywtx_div_content_router_img">
                <img :src="item.url" alt="" class="img" />
              </div>
              <div class="ywtx_div_content_router_li_div">
                <span>{{ item.name }}</span>
                <div>了解详情 &rharu;</div>
              </div>
            </li>
          </ul>
          <ul class="ywtx_div_content_right_router" v-if="item.type === 4">
            <li
            :class="item.jinzhi ===false ? 'jinzhi':''"
              v-for="item in type4"
              :key="item.id"
              @click="dianjitiaozhuan(item.id)"
            >
              <div class="ywtx_div_content_router_img">
                <img :src="item.url" alt="" class="img" />
              </div>
              <div class="ywtx_div_content_router_li_div">
                <span>{{ item.name }}</span>
                <div>了解详情 &rharu;</div>
              </div>
            </li>
          </ul>
          <ul class="ywtx_div_content_right_router" v-if="item.type === 5">
            <li
            :class="item.jinzhi ===false ? 'jinzhi':''"
              v-for="item in type5"
              :key="item.id"
              @click="dianjitiaozhuan(item.id)"
            >
              <div class="ywtx_div_content_router_img">
                <img :src="item.url" alt="" class="img" />
              </div>
              <div class="ywtx_div_content_router_li_div">
                <span>{{ item.name }}</span>
                <div>了解详情 &rharu;</div>
              </div>
            </li>
          </ul>
		  <ul class="ywtx_div_content_right_router" v-if="item.type === 6">
		    <li
		    :class="item.jinzhi ===false ? 'jinzhi':''"
		      v-for="item in type6"
		      :key="item.id"
			  @click="itemPush(6)"
		    >
		      <div class="ywtx_div_content_router_img">
		        <img :src="item.url" alt="" class="img" />
		      </div>
		      <div class="ywtx_div_content_router_li_div">
		        <span>{{ item.name }}</span>
		        <div>了解详情 &rharu;</div>
		      </div>
		    </li>
		  </ul>
        </div>
        <!-- <router-view></router-view> -->
      </div>
    </div>
  </div>
</template>

<script>
import { business } from "@/api/yewutixi";
export default {
  data() {
    return {
      ywtx: {},
      type1: [
        {
          id: 1,
          name: "成人高考",
          url: require("../../assets/yewutixi/业务体系_01.png"),
        },
        {
          id: 2,
          name: "自考",
          url: require("../../assets/yewutixi/业务体系_02.png"),
        },
        {
          id: 3,
          name: "国家开放大学",
          url: require("../../assets/yewutixi/业务体系_03.png"),
        },
        {
          id: 10,
          name: "电大中专",
          url: require("../../assets/yewutixi/业务体系新加图标_01.png"),
          jinzhi: false,
        },
      ],
	  type6: [
	    {
	      id: 1,
	      name: "高起本",
	      url: require("../../assets/yewutixi/业务体系_01.png"),
	    },
	    {
	      id: 2,
	      name: "专插本",
	      url: require("../../assets/yewutixi/业务体系_02.png"),
	    },
	    {
	      id: 3,
	      name: "韩国硕士",
	      url: require("../../assets/yewutixi/业务体系_03.png"),
	    },
	    {
	      id: 10,
	      name: "韩国博士",
	      url: require("../../assets/yewutixi/业务体系新加图标_01.png"),
	      jinzhi: false,
	    },
	  ],
      type2: [
        {
          id: 4,
          name: "助理工程师",
          url: require("../../assets/yewutixi/业务体系_05.png"),
        },
        {
          id: 5,
          name: "中级工程师",
          url: require("../../assets/yewutixi/业务体系_06.png"),
        },
        {
          id: 6,
          name: "副高级工程师",
          url: require("../../assets/yewutixi/业务体系_07.png"),
        },
        {
          id: 11,
          name: "正高级工程师",
          url: require("../../assets/yewutixi/业务体系新加图标_02.png"),
          jinzhi: false,
        },
      ],
      type3: [
        {
          id: 7,
          name: "职业学校教育",
          url: require("../../assets/yewutixi/业务体系_09.png"),
        },
        {
          id: 8,
          name: "职业培训",
          url: require("../../assets/yewutixi/业务体系新加图标_03.png"),
        },
        {
          id: 12,
          name: "在职培训",
          url: require("../../assets/yewutixi/业务体系新加图标_04.png"),
          jinzhi: false,
        },
        {
          id: 13,
          name: "创业培训",
          url: require("../../assets/yewutixi/业务体系新加图标_05.png"),
          jinzhi: false,
        },
      ],
      type4: [
        {
          id: 9,
          name: "统招专升本",
          url: require("../../assets/yewutixi/业务体系_11.png"),
        },
        {
          id: 14,
          name: "单独招生",
          url: require("../../assets/yewutixi/业务新增_01.png"),
          jinzhi: false,
        },
        {
          id: 15,
          name: "课程服务",
          url: require("../../assets/yewutixi/业务新增_02.png"),
          jinzhi: false,
        },
        {
          id: 16,
          name: "专业规划",
             url: require("../../assets/yewutixi/业务新增_03.png"),
          jinzhi: false,
        },
      ],
      type5: [
        {
          id: 17,
          name: "成考院校",
             url: require("../../assets/yewutixi/业务新增_04.png"),
        },
        {
          id: 18,
          name: "教育政策",
             url: require("../../assets/yewutixi/业务新增_05.png"),
          jinzhi: false,
        },
        {
          id: 19,
          name: "最新动态",
             url: require("../../assets/yewutixi/业务新增_06.png"),
          jinzhi: false,
        },
        {
          id: 20,
          name: "教育改革",
             url: require("../../assets/yewutixi/业务新增_07.png"),
          jinzhi: false,
        },
      ],
    };
  },
  created() {
    this.create();
  },
  methods: {
    async create() {
      const ywtx = await business();
	  
	  let addhgData={
		  id:6,
		  image :  require("../../assets/xm222.png"),
		  image_big : require("../../assets/xm1.png"),
		  // https://admin.xuemengedu.com/uploads/20231103/b4e16a0d1c0067a1b11b12b419e6f9b5.jpg
		  intro : 
		  "学盟留学作为集团发展的新晋品牌，国内与中国海洋大学合作韩国本硕预科项目，国外与多所韩国知名高等学府合作开展本科0+4、1年制专插本、硕博研究生、博士后等多层次平价留学项目，双语授课，专业设置广泛，文凭含金量高，回国后享受留学归国人员待遇，符合北上广等地落户条件。韩国为外国人学生提供了丰厚的奖学金，进入韩国大学就读，90%以上的中国留学生可以获得30%以上的学费减免。",
		  name :  "出国留学",
		  title : "国外与多所韩国知名高等学府合作开展....",
		  type :  6
	  }
	  
	  ywtx.splice(1, 0, addhgData);
      this.ywtx = ywtx;
      console.log(this.ywtx);
    },
    // 点击每一个title 跳转到另一个页面

    itemPush(id) {
		
		if (id === 6) {
		  var idnum1 = this.ywtx.find((item) => item.id === 6);
		  console.log('idnum1',idnum1)
		  // return
		  this.$router.push({
		    name: "WorkingItem",
		    params: {
		      id: JSON.stringify(idnum1),
		    },
		  });
		}
		
      if (id === 1) {
        var idnum1 = this.ywtx.find((item) => item.id === 1);
        this.$router.push({
          name: "WorkingItem",
          params: {
            id: JSON.stringify(idnum1),
            type: JSON.stringify(this.type1),
          },
        });
      } else if (id === 2) {
        var idnum2 = this.ywtx.find((item) => item.id === 2);
        this.$router.push({
          name: "WorkingItem",
          params: {
            id: JSON.stringify(idnum2),
            type: JSON.stringify(this.type2),
          },
        });
      } else if (id === 3) {
        var idnum3 = this.ywtx.find((item) => item.id === 3);
        this.$router.push({
          name: "WorkingItem",
          params: {
            id: JSON.stringify(idnum3),
            type: JSON.stringify(this.type3),
          },
        });
      } else if (id === 4) {
        var idnum4 = this.ywtx.find((item) => item.id === 4);
        this.$router.push({
          name: "WorkingItem",
          params: {
            id: JSON.stringify(idnum4),
            type: JSON.stringify(this.type4),
          },
        });
      } else if (id === 5) {
        var idnum5 = this.ywtx.find((item) => item.id === 5);
        this.$router.push({
          name: "WorkingItem",
          params: {
            id: JSON.stringify(idnum5),
            type: JSON.stringify(this.type5),
          },
        });
      }

      // else if(type === 2){

      // }else if(type === 2){

      // }
      // console.log(type);
      // console.log(1);
    },
    // xuelijiaoyu() {
    //   this.$router.push("/WorkingSystem/XLJY");
    // },
    dianjitiaozhuan(id) {
      if (id === 1) {
        this.$router.push("/WorkingSystem/XLJY/CRGK");
      } else if (id === 2) {
        this.$router.push("/WorkingSystem/XLJY/ZK");
      } else if (id === 3) {
        this.$router.push("/WorkingSystem/XLJY/GJKF");
      } else if (id === 4) {
        this.$router.push("/WorkingSystem/ZCSB/ChuJi");
      } else if (id === 5) {
        this.$router.push("/WorkingSystem/ZCSB/ZhongJi");
      } else if (id === 6) {
        this.$router.push("/WorkingSystem/ZCSB/GaoJi");
      } else if (id === 7) {
        this.$router.push("/WorkingSystem/ZYJY/xuexiao");
      } else if (id === 8) {
        this.$router.push("/WorkingSystem/ZYJY/peixun");
      } else if (id === 9) {
        this.$router.push("/WorkingSystem/ZSB");
      }
      // 禁止跳转
      // // 电大中专
      // else if (id === 10) {
      //   this.$router.push("/WorkingSystem/XLJY/DDZZ");
      // }
      // // 正高级工程师
      // else if (id === 11) {
      //   this.$router.push("/WorkingSystem/ZCSB/ZhengGaoJi");
      // }
      // 成考院校
      else if (id === 17) {
        this.$router.push("/WorkingSystem/CKYX");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.ywtx_div {
  width: 1400px;
  margin: 0 auto;
  // 业务体系标题
  .ywtx_h1 {
    margin-top: 50px;
    color: #30384d;
    font-size: 34px;
    font-weight: 600;
    text-align: center;
    position: relative;
    &::after {
      content: " ";
      position: absolute;
      top: 60px;
      left: 660px;
      width: 80px;
      height: 5px;
      background-color: #9b0e12;
    }
  }
  // 内容
  .ywtx_div_content {
    margin-top: 50px;
    height: 470px;
    //   background-color: skyblue;
    display: flex;
    justify-content: space-between;

    // &:nth-child(odd) .ywtx_div_content_left {
    //   order:1
    // }
    &:nth-child(odd) {
      /* 使用flexbox布局 */
      display: flex;
      /* 反转主轴方向，使左右子盒子交换位置 */
      flex-direction: row-reverse;
    }

    .ywtx_div_content_left {
      width: 715px;
      height: 100%;
      background-color: #9b0e12;
    }
    .ywtx_div_content_right {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      width: 630px;
      height: 100%;
      // background-color: red;

      // 右侧盒子的标题
      .ywtx_div_content_right_title {
        display: flex;
        color: #9b0e12;
        .ywtx_div_content_right_h1 {
          position: relative;
          font-size: 27px;
          font-weight: 600;
		  cursor: pointer;
          &:hover {
            opacity: 0.6;
          }
          &:active {
            opacity: 0.9;
          }
          &::after {
            content: " ";
            position: absolute;
            top: 10px;
            left: 125px;
            width: 3px;
            height: 22px;
            background-color: #9b0e12;
          }
        }
        .ywtx_div_content_right_span {
          margin-top: 7px;
          margin-left: 30px;
          font-size: 18px;
        }
      }
      // 内容
      .ywtx_div_content_right_jieshao {
        // margin-top: 55px;
        width: 590px;
        line-height: 25px;
        font-size: 16px;
        color: #30384d;
        text-indent: 2em;
      }

      // 跳转的整体盒子
      .ywtx_div_content_right_router {
        // margin-top: 55px;
        // margin: 0 auto;
        margin-left: 40px;
        width: 495px;
        // background-color: red;
        // height: 205px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        li {
          margin-bottom: 10px;
          width: 240px;
          height: 90px;
          border: 1px solid #a7aeb7;
          display: flex;
          justify-content: center;
          border-radius: 5px;
		  cursor: pointer;
          &:hover {
            border: 1px solid #9b0e12;
          }
          &:active {
            opacity: 0.6;
          }

          .ywtx_div_content_router_img {
            margin-left: 17px;
            margin-top: 25px;
            width: 40px;
            height: 40px;
          }
          .ywtx_div_content_router_li_div {
            margin-top: 20px;
            margin-left: 15px;
            width: 100px;
            height: 48px;
            display: flex;
            justify-content: space-between;
            flex-direction: column;

            span {
              font-size: 16px;
              color: #32374f;
            }
            div {
              font-size: 15px;
              color: #9b0e12;
            }
          }
        }
      }
    }
  }
  // 鼠标经过禁止跳转样式
  .jinzhi {
    &:hover {
      border: 1px solid #a7aeb7;
      filter: grayscale(100%);
      cursor: not-allowed;
    }
  }
}
</style>