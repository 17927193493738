import request from '@/utils/request'

export  function teacher4srv() {
    return request({
      url: 'api/api/teacher4srv',
      method: 'post'
    })
  }
  // 十大理由
export  function cause10() {
    return request({
      url: 'api/api/cause10',
      method: 'post'
    })
  }
  // 学员风采
export  function students() {
    return request({
      url: 'api/api/students',
      method: 'post'
    })
  }