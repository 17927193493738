import request from '@/utils/request'
// 上面的照片
export  function banner() {
    return request({
      url: 'api/api/banner',
      method: 'post'
    })
  }
export  function xuemengintro() {
    return request({
      url: 'api/api/xuemengintro',
      method: 'post'
    })
  }
//   发展历程
export  function xuemenghistory() {
    return request({
      url: 'api/api/xuemenghistory',
      method: 'post'
    })
  }

//   企业活动
export  function activity() {
    return request({
      url: 'api/api/activity',
      method: 'post'
    })
  }
//   团队风采
export  function team() {
    return request({
      url: 'api/api/team',
      method: 'post'
    })
  }
//   集团荣耀
export  function companyhonour() {
    return request({
      url: 'api/api/companyhonour',
      method: 'post'
    })
  }
//   集团荣耀 大图
export  function companyhonourb() {
    return request({
      url: 'api/api/companyhonourb',
      method: 'post'
    })
  }
//   集团荣耀 大图
export  function schoolguanhuai () {
    return request({
      url: 'api/api/schoolguanhuai ',
      method: 'post'
    })
  }

