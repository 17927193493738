import request from '@/utils/request'
// 业务体系
export  function business() {
    return request({
      url: 'api/api/business',
      method: 'post'
    })
  }

  // 成人高考
  export  function business1() {
    return request({
      url: 'api/api/business1',
      method: 'post'
    })
  }
  // 国开
  export  function business3() {
    return request({
      url: 'api/api/business3',
      method: 'post'
    })
  }
  // 自考
  export  function business2() {
    return request({
      url: 'api/api/business2',
      method: 'post'
    })
  }
    // 初级
    export  function business4() {
      return request({
        url: 'api/api/business4',
        method: 'post'
      })
    }
    // 初级
    export  function business5() {
      return request({
        url: 'api/api/business5',
        method: 'post'
      })
    }
    // 初级
    export  function business6() {
      return request({
        url: 'api/api/business6',
        method: 'post'
      })
    }

    // 职业教育
    export  function business7() {
      return request({
        url: 'api/api/business7',
        method: 'post'
      })
    }
    // 职业培训
    export  function business8() {
      return request({
        url: 'api/api/business8',
        method: 'post'
      })
    }
    // 专升本
    export  function business9() {
      return request({
        url: 'api/api/business9',
        method: 'post'
      })
    }
     // 成考院校
     export  function chengkaoschool() {
      return request({
        url: 'api/api/chengkaoschool',
        method: 'post'
      })
    }
     // 成考院校/学院动态
     export  function chengkaoschoolnewsd() {
      return request({
        url: 'api/api/chengkaoschoolnewsd',
        method: 'post'
      })
    }
     // 成考院校/学院要闻
     export  function chengkaoschoolnewsy() {
      return request({
        url: 'api/api/chengkaoschoolnewsy ',
        method: 'post'
      })
    }
    
