<template>
  <div class="xzxm_box">
    <ul>
      <li v-for="item in xuemeng " :key="item.id">
        <div class="xzxm_li_box">
          <div class="xzxm_li_icon">
            <img :src="item.image" alt="" class="img">
          </div>
          <div class="xzxm_li_right">
            <h1>{{item.title}}</h1>
            <div class="xzxm_li_right_jieshao">
                {{item.content}}
            </div>  
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { cause10} from "@/api/xuemengfuwu";
export default {
  data() {
    return {
      xuemeng:{},
    };
  },
  created() {
    this.create();
  },
  methods: {
    async create() {
      const xuemeng = await cause10();
      
      this.xuemeng=xuemeng
      console.log(this.xuemeng);
    },
  },
};
</script>

<style lang="scss" scoped>
.img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}
  .xzxm_box{
      ul{
        margin: 0 auto;
        width: 1275px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        li{
          margin-top: 15px;
          background-color: #fdfefe;
          border: 0.5px solid #fefefe;
          width: 630px;
          height: 255px;
          display: flex;
          justify-content: center;
          align-items: center;

          .xzxm_li_box{
            width: 541px;
            height: 124px;
            display: flex;
            justify-content: space-between;
            .xzxm_li_icon{
              height: 62px;
              width: 54px;
            }
            .xzxm_li_right{
              flex: 1;
              height: 90%;
              margin-left: 35px;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              h1{
                margin-bottom: 15px;
                font-size: 26px;
                font-weight: 600;
                color: #30384f;
              }
              .xzxm_li_right_jieshao{
                text-indent: 2em;
                  width: 100%;
                  color: #30384f;
                  font-size: 16px;
                  line-height: 30px;
              }
            }
          }
        }
      }
  }
</style>