<template>
  <div>
    <div class="hangyeitem_img">
      <img :src="Image" alt="" class="img" />
    </div>
    <div class="xiaoqu_item">
      <h1 class="xiaoquitem_h1">{{ xiaoqu.title }}</h1>
      <div class="titme">{{ xiaoqu.createtime }}</div>
      <div v-html="xiaoqu.content" class="xiaoquitem_content"></div>
    </div>
  </div>
</template>

<script>
import { newsstoryc } from "@/api/xinwenzhongxin";
import { banner } from "@/api/intoxuemeng";
export default {
  data() {
    return {
      // 首页照片
      Image: "",
      xiaoqu: {},
      idd: 0,
    };
  },
  created() {
    // this.create();
    this.banner();
    this.fuzhi();
  },
  watch: {
    $route: {
      handler: function (val) {
        if (val.name !== "xiaoquItem") {
          localStorage.removeItem("paramsData");
        }
        if (val.params.id == undefined) {
          this.idd = 1;
        } else {
          this.idd = val.params.id;
          this.create(this.idd);
        }
      },
      // 深度观察监听
      deep: true,
      immediate: true,
    },
  },
   // 页面销毁之前
  // beforeDestroy() {
  //   localStorage.removeItem("paramsData");
  // },
  methods: {
    async banner() {
      const { news } = await banner();
      this.Image = news;
    },
    async create(id) {
      const data = await newsstoryc({ id: id });
      this.xiaoqu = data;
      
      localStorage.setItem("paramsData", JSON.stringify(this.xiaoqu));  
    },
    // 本地存储 刷新页面的时候 用本地存储的数据
    async fuzhi() {
      this.xiaoqu = await JSON.parse(localStorage.getItem("paramsData"));
    },
  },
};
</script>

<style lang="scss" scoped>
// img{
//   width: 100%;
//   height: 100%;

// }
.img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.xiaoqu_item {
  width: 1000px;
  // background-color: blue;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  // align-items: center;
  .xiaoquitem_h1 {
    margin-top: 50px;
    font-size: 36px;
    color: #31374c;
    font-weight: bold;
    line-height: 42px;
    padding-bottom: 20px;
    text-align: center;
  }
  .titme {
    text-align: center;
    font-size: 16px;
    color: #a1a8b2;
    line-height: 19px;
    padding-bottom: 30px;
    border-bottom: 1px solid #f0eff5;
    margin-bottom: 40px;
  }
  // .xiaoquitem_content {
  //   height: 100%;
  //   display: flex;
  //   flex-direction: column;
  //   justify-content: center;
  //   /deep/ p {
  //     display: flex;
  //     justify-content: center;
  //   }
  // }
}
</style>