<template>
  <div class="xyfc_box">
    <h1 class="xyfc__h1">学员风采</h1>
    <ul class="xyfc_box_div">
      <li v-for="(item, index) in xuemeng.images" :key="index">
        <img :src="item" alt="" class="img">
      </li>
    </ul>
  </div>
</template>

<script>
import { students} from "@/api/xuemengfuwu";
export default {
  data() {
    return {
      xuemeng:{},
    };
  },
  created() {
    this.create();
  },
  methods: {
    async create() {
      const xuemeng = await students();
      this.xuemeng=xuemeng
      // console.log(this.xuemeng);
    },
  },
};
</script>

<style lang="scss" scoped>
.img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.xyfc_box {
   margin-top: 50px;
  .xyfc__h1 {
    color: #30384d;
    font-size: 34px;
    font-weight: 600;
    text-align: center;
    position: relative;
    &::after {
      content: " ";
      position: absolute;
      top: 60px;
      left: 660px;
      width: 80px;
      height: 5px;
      background-color: #9b0e12;
    }
  }
  .xyfc_box_div {
    margin-top: 80px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    li {
      height: 430px;
      margin-top: 20px;
      width: 32.5%;
      background-color: #30384d;
      &:nth-child(5n + 4),
      &:nth-child(5n + 5) {
        width: 49.3%;
      }
    }
  }
}
</style>