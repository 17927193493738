<template>
  <div>
    <div class="hangyeitem_img">
      <img :src="Image" alt="" class="img" />
    </div>
    <div class="shipin_item">
      <h1 class="shipinitem_h1">
        {{ shipin.title }}
      </h1>
      <div class="titme">
        {{ shipin.createtime }}
      </div>
      <div class="shipinitem_content">
        <video :src="shipin.url" controls></video>
      </div>
    </div>
  </div>
</template>

<script>
import { newsvideoc } from "@/api/xinwenzhongxin";
import { banner } from "@/api/intoxuemeng";
export default {
  data() {
    return {
      // 首页照片
      Image: "",
      shipin: {},
      id: 0,
    };
  },
  created() {
    // this.create();
    this.banner();
    this.fuzhi();
  },
  watch: {
    $route: {
      handler: function (val) {
        if (val.name !== "shipinItem") {
          localStorage.removeItem("paramsData");
        }
        if (val.params.id == undefined) {
          this.idd = 1;
        } else {
          this.idd = val.params.id;
          this.create(this.idd);
        }
      },
      // 深度观察监听
      deep: true,
      immediate: true,
    },
  },
  // 页面销毁之前
  // beforeDestroy() {
  //   localStorage.removeItem("paramsData");
  // },
  methods: {
    async banner() {
      const { news } = await banner();
      this.Image = news;
    },
    async create(id) {
      const data = await newsvideoc({ id: id });
      this.shipin = data;
      // console.log(this.shipin,'shipin');
      localStorage.setItem("paramsData", JSON.stringify(this.shipin));
    },
    // 本地存储 刷新页面的时候 用本地存储的数据
    async fuzhi() {
      this.shipin = await JSON.parse(localStorage.getItem("paramsData"));
    },
  },
};
</script>

<style lang="scss" scoped>
.img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.shipin_item {
  width: 1100px;
  // background-color: blue;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  // align-items: center;
  .shipinitem_h1 {
    margin-top: 50px;
    font-size: 36px;
    color: #31374c;
    font-weight: bold;
    line-height: 42px;
    padding-bottom: 20px;
    text-align: center;
  }
  .titme {
    text-align: center;
    font-size: 16px;
    color: #a1a8b2;
    line-height: 19px;
    padding-bottom: 30px;
    border-bottom: 1px solid #f0eff5;
    margin-bottom: 40px;
  }
  // .shipinitem_content {
  //   height: 100%;
  //   display: flex;
  //   flex-direction: column;
  //   justify-content: center;
  //   /deep/ p {
  //     display: flex;
  //     justify-content: center;
  //   }
  // }
}
</style>