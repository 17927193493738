<template>
  <div>
    <div class="ccyx" v-show="$route.meta.showfater">
      <ul class="ccyx_ul">
        <li v-for="item in yuanxiao" :key="item.id" @click="tiaozhuan(item.id)">
          <div class="ccyx_ul_top">
            <div class="_logo">
              <img :src="item.image" alt="" class="img"/>
            </div>
          </div>
          <div class="ccyx_ul_bottom">{{ item.name }}</div>
        </li>
      </ul>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
import { chengkaoschool } from "@/api/yewutixi";
export default {
  data() {
    return {
      yuanxiao: {},
    };
  },
  created() {
    this.create();
  },
  methods: {
    async create() {
      this.yuanxiao = await chengkaoschool();
    },
    tiaozhuan(id) {
      this.$router.push({
        name: "xiangqing",
        params: {
          id: id,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.ccyx {
  width: 1400px;
  margin: 0 auto;
  .ccyx_ul {
    margin-top: 85px;
    display: flex;
    flex-wrap: wrap;
    // justify-content: space-between;
    li {
      height: 310px;
      width: 320px;
      margin-right: 20px;
      border: 1.5px solid #9b0e12;
      margin-bottom: 40px;
      display: flex;
      flex-direction: column;
      .ccyx_ul_top {
        width: 100%;
        height: 240px;
        display: flex;
        justify-content: center;
        align-items: center;
        ._logo {
          width: 192px;
          height: 192px;
          // background-color: yellow;
        }
      }
      .ccyx_ul_bottom {
        flex: 1;
        width: 100%;
        background-color: #9b0e12;
        font-size: 20px;
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
</style>