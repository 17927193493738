var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"whln_box"},[_c('div',{staticClass:"whln_box_top"},[_c('ul',{staticClass:"whln_box_top_ui"},[_c('li',{staticClass:"whln_box_top_li"},[_vm._m(0),_vm._m(1),_c('div',{staticClass:"whln_box_top_li_jieshao"},[_vm._v(" "+_vm._s(_vm.yuanjing.vision)+" ")])]),_c('li',{staticClass:"whln_box_top_li"},[_vm._m(2),_vm._m(3),_c('div',{staticClass:"whln_box_top_li_jieshao"},[_vm._v(" "+_vm._s(_vm.yuanjing.mission)+" ")])]),_c('li',{staticClass:"whln_box_top_li"},[_vm._m(4),_vm._m(5),_c('div',{staticClass:"whln_box_top_li_jieshao"},[_vm._v(" "+_vm._s(_vm.yuanjing.idea)+" ")])]),_c('li',{staticClass:"whln_box_top_li"},[_vm._m(6),_vm._m(7),_c('div',{staticClass:"whln_box_top_li_jieshao"},[_vm._v(" "+_vm._s(_vm.yuanjing.values)+" ")])])])]),_c('div',{staticClass:"whln_qyhd"},[_c('h1',{staticClass:"whln_qyhd_h1"},[_vm._v("企业活动")]),_c('ul',{staticClass:"whln_qyhd_ul"},_vm._l((_vm.qiyehuodong),function(item,index){return _c('li',{key:index},[_c('img',{staticClass:"img",attrs:{"src":item,"alt":""}})])}),0)]),_c('div',{staticClass:"whln_qyhd"},[_c('h1',{staticClass:"whln_qyhd_h1"},[_vm._v("团队风采")]),_c('ul',{staticClass:"whln_qyhd_ul"},_vm._l((_vm.tuandui),function(item,index){return _c('li',{key:index},[_c('img',{staticClass:"img",attrs:{"src":item,"alt":""}})])}),0)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"whln_box_top_li_icon"},[_c('img',{attrs:{"src":require("@/assets/愿景.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"whln_box_top_li_title"},[_vm._v("愿景 "),_c('span',{staticClass:"whln_box_top_li_ENGLISH"},[_vm._v("Vision")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"whln_box_top_li_icon"},[_c('img',{attrs:{"src":require("@/assets/使命.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"whln_box_top_li_title"},[_vm._v("使命 "),_c('span',{staticClass:"whln_box_top_li_ENGLISH"},[_vm._v("mission")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"whln_box_top_li_icon"},[_c('img',{attrs:{"src":require("@/assets/理念.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"whln_box_top_li_title"},[_vm._v("理念 "),_c('span',{staticClass:"whln_box_top_li_ENGLISH"},[_vm._v("idea")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"whln_box_top_li_icon"},[_c('img',{attrs:{"src":require("@/assets/价值观.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"whln_box_top_li_title"},[_vm._v("价值观 "),_c('span',{staticClass:"whln_box_top_li_ENGLISH"},[_vm._v("values")])])
}]

export { render, staticRenderFns }