import Vue from 'vue'
import App from './App.vue'
import router from './router'
import '@/styles/reset.css' //初始化样式
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import BaiduMap from 'vue-baidu-map'
Vue.use(ElementUI);
import 'amfe-flexible'
import './assets/css/index.css'

import * as filters from '@/filters'

Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})


Vue.config.productionTip = false

Vue.use(BaiduMap, {
  // ak 是在百度地图开发者平台申请的密钥 详见 http://lbsyun.baidu.com/apiconsole/key */
  ak: '1g4XwF7IIt5CsRZKqySXxwNiz43AfF4W'
})

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')

router.afterEach((to, from, next) => {
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0
})
