<template>
  <div class="zypx_box">
    <!-- yewutixi -->
    <div class="yewutixi">
      <h1 class="zypx_h1">职业培训简介</h1>
      <div class="zypx_jieshao">
        {{ chengren.intro }}
      </div>
    </div>

    <!-- jiaoxueyoushi -->
    <h1 class="zypx_h1_2">职业培训优势</h1>
    <!-- 教学优势 -->
    <div class="tuobiao">
      <div class="zypx_jiaoxueyoushi">
        <ul class="zypx_jiaoxueyoushi_ul">
          <li v-for="item in chengren.youshi" :key="item.id">
            <div class="zypx_jiaoxueyoushi_ul_div">
              <img :src="item.image" alt="" class="img" />
            </div>
            <span class="zypx_jiaoxueyoushi_ul_span">{{ item.title }}</span>
            <div class="zypx_jiaoxueyoushi_ul_jieshao">
              {{ item.content }}
            </div>
          </li>
        </ul>
      </div>
    </div>

    <div class="zypx_biyezhengshu">
      <div class="zypx_biyezhengshu_left">
        <div class="icon">
          <img src="~@/assets/引号.png" alt="" class="img"/>
        </div>
        <div class="text">芜职业技术学院毕业证书</div>
      </div>
      <ul class="zypx_biyezhengshu_right">
        <li v-for="(item, index) in chengren.cers" :key="index">
          <img class="img" :src="item" alt="" />
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { business8 } from "@/api/yewutixi";
export default {
  data() {
    return {
      chengren: {},
    };
  },
  created() {
    this.create();
  },
  methods: {
    async create() {
      const chengren = await business8();
      this.chengren = chengren;
      // console.log(chengren);
    },
  },
};
</script>

<style lang="scss" scoped>
.img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.zypx_box {
  .yewutixi {
    width: 1400px;
    margin: 0 auto;
    .zypx_h1 {
      margin-top: 50px;
      margin-bottom: 50px;
      color: #30384d;
      font-size: 25px;
      font-weight: 600;
      text-align: center;
      position: relative;
      &::after {
        content: " ";
        position: absolute;
        top: 50px;
        left: 50%;
        margin-left: -40px;
        width: 80px;
        height: 5px;
        background-color: #9b0e12;
      }
    }
    .zypx_jieshao {
      // height: 200px;
      margin-bottom: 50px;
      font-size: 20px;
      line-height: 40px;
    }
  }

  .zypx_h1_2 {
    // margin-bottom: 800px;
    color: #30384d;
    font-size: 25px;
    font-weight: 600;
    text-align: center;
    position: relative;
    &::after {
      content: " ";
      position: absolute;
      top: 50px;
      left: 50%;
      margin-left: -40px;
      width: 80px;
      height: 5px;
      background-color: #9b0e12;
    }
  }
  // tuobiao
  .tuobiao {
    // width: 1400px;
    margin: 0 auto;
    margin-top: 50px;
    position: relative;
    height: 500px;
    .zypx_jiaoxueyoushi {
      position: absolute;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #9b0e12;
      .zypx_jiaoxueyoushi_ul {
        width: 100%;
        height: 100%;
        margin: 0 auto;
        display: flex;
        align-items: center;
        li {
          width: 33%;
          height: 75%;
          display: flex;
          flex-direction: column;
          align-items: center;

          .zypx_jiaoxueyoushi_ul_div {
            width: 72px;
            height: 76px;
            // background-color: #fff;
            margin-bottom: 30px;
          }
          .zypx_jiaoxueyoushi_ul_span {
            font-size: 20px;
            color: #fff;
            font-weight: 600;
          }
          .zypx_jiaoxueyoushi_ul_jieshao {
            margin-top: 30px;
            width: 500px;
            color: #fff;
            text-align: center;
            font-size: 17px;
          }
        }
      }
    }
  }

  .zypx_biyezhengshu {
    margin: 0 auto;
    margin-top: 50px;
    width: 1400px;
    height: 330px;
    // background-color: #30384d;
    display: flex;
    justify-content: space-between;
    .zypx_biyezhengshu_left {
      width: 315px;
      background-color: #9b0e12;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      .icon {
        position: absolute;
        top: 70px;
        left: 40px;
        width: 30px;
      }
      .text {
        width: 230px;
        font-size: 30px;
        font-weight: 600;
        color: #fff;
      }
    }
    .zypx_biyezhengshu_right {
      // flex: ;
      width: 1000px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      li {
        width: 325px;
        height: 220px;
        background-color: #30384d;
          margin-right: 10px;
      }
    }
  }
}
</style>