<template>
  <div>
    <div class="hangyeitem_img">
      <img :src="Image" alt="" class="img" />
    </div>
    <div class="hangyeitem">
      <h1 class="hangyeitem_h1">{{ hangye.title }}</h1>
      <div class="titme">{{ hangye.createtime }}</div>
      <div v-html="hangye.content" class="hangyeitem_content"></div>
    </div>
  </div>
</template>

<script>
import { newstradec } from "@/api/xinwenzhongxin";
import { banner } from "@/api/intoxuemeng";
export default {
  data() {
    return {
      // 首页照片
      Image: "",
      hangye: {},
      idd: 0,
    };
  },
  created() {
    this.banner();
    // console.log(1);
    this.fuzhi();
  },
  
  watch: {
    $route: {
      handler: function (val) {
        // console.log(val);
        if(val.name !=='hangyeItem'){
           localStorage.removeItem("paramsData");
        }
        if (val.params.id == undefined) {
          this.idd = 1;
        } else {
          this.idd = val.params.id;
          this.create(this.idd);
          // }
        }
      },
      // 深度观察监听
      deep: true,
      immediate: true,
    },
  },
 
  methods: {
    async banner() {
      const { news } = await banner();
      this.Image = news;
    },
    async create(id) {
      const data = await newstradec({ id: id });
      this.hangye = data;
      localStorage.setItem("paramsData", JSON.stringify(this.hangye));
    },
    // 本地存储 刷新页面的时候 用本地存储的数据
    async fuzhi() {
      this.hangye = await JSON.parse(localStorage.getItem("paramsData"));
    },
  },

    // 页面销毁之前
  // beforeDestroy() {
  //   localStorage.removeItem("paramsData");
  // },
  
};
</script>

<style lang="scss" scoped>
img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.hangyeitem {
  // width: 700px;
  // background-color: blue;
    width: 1100px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  // align-items: center;
  .hangyeitem_img {
    width: 100%;
    height: 100px;
    // background-color: blue;
  }
  .hangyeitem_h1 {
    margin-top: 50px;
    font-size: 36px;
    color: #31374c;
    font-weight: bold;
    line-height: 42px;
    padding-bottom: 20px;
    text-align: center;
  }
  .titme {
    text-align: center;
    font-size: 16px;
    color: #a1a8b2;
    line-height: 19px;
    padding-bottom: 30px;
    border-bottom: 1px solid #f0eff5;
    margin-bottom: 40px;
  }
}
</style>