<template>
  <div class="crgk_box">
    <h1 class="crgk_h1">业务体系</h1>
    <div class="crgk_jieshao">
      {{chengren.intro}}
    </div>
    <h1 class="crgk_h1_2">完善的课程体系</h1>

    <!-- 脱标 -->
    <div class="crgk_kechengtixi">
      <ul class="crgk_kechengtixi_ul">
        <li v-for="item in chengren.tixi" :key="item.id">
          <div class="crgk_kechengtixi_ul_img">
            <img :src="item.image" alt="" class="img">
          </div>
          <span class="crgk_kechengtixi_ul_span">{{item.title}}</span>
          <div class="crgk_kechengtixi_ul_div">
            {{item.content}}
          </div>
        </li>
      </ul>
    </div>

    <!-- 报考流程 -->
    <div class="crgk_baokao">
      <div class="crgk_item_top_left">
        <div class="icon">
          <img src="~@/assets/引号.png" alt="" />
        </div>
        <div class="text">高级职称 服务流程</div>
      </div>
    </div>

    <!-- 报考流程下面的图标 -->
    <ul class="crgk_baokao_icon">
      <li v-for="item in chengren.liucheng" :key="item.id">
        <div class="crgk_baokao_icon_div">
          <img :src="item.image" alt="" class="img">
        </div>
        <span>{{item.title}}</span>
      </li>
    </ul>

    <!-- 毕业证书 -->
    <div class="crgk_biyezhengshu">
      <div class="crgk_biyezhengshu_left">
        <div class="icon">
          <img src="~@/assets/引号.png" alt="" />
        </div>
        <div class="text">高级职称毕业证书</div>
      </div>
      <ul class="crgk_biyezhengshu_right">
        <li v-for="(item,index) in chengren.cers" :key='index'>
          <img class="img" :src="item" alt="">
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { business6 } from "@/api/yewutixi";
export default {
  data() {
    return {
      chengren:{},
    };
  },
  created() {
    this.create();
  },
  methods: {
    async create() {
      const chengren = await business6();
      this.chengren=chengren
    },
  },
};
</script>

<style lang="scss" scoped>
.img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}
html {
  position: relative;
}
.crgk_box {
  .crgk_h1 {
    margin-top: 100px;
    margin-bottom: 90px;
    color: #30384d;
    font-size: 25px;
    font-weight: 600;
    text-align: center;
    position: relative;
    &::after {
      content: " ";
      position: absolute;
      top: 50px;
      left: 660px;
      width: 80px;
      height: 5px;
      background-color: #9b0e12;
    }
  }
  .crgk_h1_2 {
    margin-top: 100px;
    // 因为下面脱标了  所以高度要给它加上
    margin-bottom: 735px;
    color: #30384d;
    font-size: 25px;
    font-weight: 600;
    text-align: center;
    position: relative;
    &::after {
      content: " ";
      position: absolute;
      top: 50px;
      left: 660px;
      width: 80px;
      height: 5px;
      background-color: #9b0e12;
    }
  }
  .crgk_jieshao {
    height: 220px;

    margin-bottom: 90px;
    font-size: 20px;
    line-height: 40px;
  }
  // 脱标了
  .crgk_kechengtixi {
    position: absolute;
    left: 0;
    top: 1220px;
    width: 100%;
    height: 580px;
    background-color: #9b0e12;
    .crgk_kechengtixi_ul {
      width: 1400px;
      height: 100%;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      li {
        height: 460px;
        width: 325px;
        background-color: #fff;
        text-align: center;
        .crgk_kechengtixi_ul_img {
          width: 250px;
          height: 167px;
          background-color: blue;
          border-radius: 0 25px;
          margin: 0 auto;
          margin-top: 30px;
          margin-bottom: 35px;
        }
        .crgk_kechengtixi_ul_span {
          font-size: 22px;
          color: #9b0e12;
          font-weight: 600;
        }
        .crgk_kechengtixi_ul_div {
          width: 260px;
          margin: 0 auto;
          margin-top: 22px;
          font-size: 16px;
          line-height: 23px;
          color: #30384d;
        }
      }
    }
  }

  .crgk_baokao {
    height: 217px;
    background-color: #9b0e12;
    .crgk_item_top_left {
      height: 100%;
      background-color: #9b0e12;
      display: flex;
      // justify-content: center;
      align-items: center;
      position: relative;
      .icon {
        position: absolute;
        top: 35px;
        left: 40px;
      }
      .text {
        margin-left: 110px;
        font-size: 30px;
        font-weight: 600;
        color: #fff;
        &::after {
          content: " ";
          position: absolute;
          top: 140px;
          left: 110px;
          width: 80px;
          height: 5px;
          background-color: #fff;
        }
      }
    }
  }

  .crgk_baokao_icon {
    display: flex;
    margin-top: 100px;
    height: 144px;
    li {
      flex: 1;
      display: flex;
      justify-content: space-around;
      flex-direction: column;
      align-items: center;
      .crgk_baokao_icon_div {
        height: 70px;
        width: 70px;
      }
    }
  }

  .crgk_biyezhengshu {
    margin-top: 110px;
    width: 100%;
    height: 330px;
    // background-color: #30384d;
    display: flex;
    justify-content: space-between;
    .crgk_biyezhengshu_left {
      width: 315px;
      background-color: #9b0e12;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      .icon {
        position: absolute;
        top: 35px;
        left: 40px;
      }
      .text {
        width: 120px;
        font-size: 30px;
        font-weight: 600;
        color: #fff;
      }
    }
    .crgk_biyezhengshu_right {
      // flex: ;
      width: 1000px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      li {
        width: 325px;
        height: 220px;
        background-color: #30384d;
      }
    }
  }
}
</style>