<template>
  <div class="xmfw_box">
    <!-- 导航栏 -->
    <div class="xmfw_img">
       <img :src="Image" alt="" class="img">
    </div>
    <div class="el_menu_box">
      <el-menu
        :default-active="this.$route.path"
        class="el-menu-demo"
        mode="horizontal"
        @select="handleSelect"
        text-color="#000"
        active-text-color="#9b0e12"
        router
      >
        <el-menu-item v-for="(item, i) in navList" :key="i" :index="item.name">
          {{ item.navItem }}
        </el-menu-item>
      </el-menu>
    </div>
    <div class="router_view">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import { banner } from "@/api/intoxuemeng";
export default {
  data() {
    return {
      activeIndex: "1",
      navList: [
        { name: "/XMFU/fourSer", navItem: "四师服务" },
        { name: "/XMFU/selectXm", navItem: "选择学盟的十大理由" },
        { name: "/XMFU/mienXm", navItem: "学员风采" },
      ],
       Image: "",
    };
  },
  created(){
    this.creat()
  },
  methods: {
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
    },
    async creat() {
      const { serve } = await banner();
      this.Image = serve;
    },
    
  },
};
</script>

<style lang="scss" scoped>
.img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.xmfw_box {
  //   // 顶部照片
  //   .xmfw_img {
  //     height: 320px;
  //     width: 100%;
  //     background-color: #9b0e12;
  //   }
  .xmfw_img {
    height: 320px;
    width: 100%;
    // background-color: #9b0e12;
  }
  // 导航栏的页面
  .el_menu_box {
    width: 1400px;
    margin: 0 auto;
    margin-top: 105px;
    .el-menu-item {
      font-size: 19px;
      font-weight: 600;
    }
  }
  // 跳转路由的页面
  .router_view {
    margin: 0 auto;
    width: 1400px;
    // background-color: skyblue;
  }
}
</style>