import request from '@/utils/request'
// 行业动态
export  function newstrade() {
    return request({
      url: 'api/api/newstrade',
      method: 'post'
    })
  }
// 行业具体
export  function newstradec(data) {
    return request({
      url: 'api/api/newstradec',
      method: 'post',
      data
    })
  }

//   集团动态
export  function newstrends(data) {
    return request({
      url: 'api/api/newstrends',
      method: 'post',
      data
    })
  }
//   集团每一项
export  function newstrendsc(data) {
    return request({
      url: 'api/api/newstrendsc',
      method: 'post',
      data
    })
  }

//   视频中心
export  function newsvideo() {
    return request({
      url: 'api/api/newsvideo',
      method: 'post'
    })
  }
  // 视频具体
export  function newsvideoc (data) {
  return request({
    url: 'api/api/newsvideoc ',
    method: 'post',
    data
  })
}
//   校区故事
export  function newsstory() {
    return request({
      url: 'api/api/newsstory',
      method: 'post'
    })
  }
  // 校区具体
export  function newsstoryc(data) {
  return request({
    url: 'api/api/newsstoryc',
    method: 'post',
    data
  })
}
