<template>
  <div class="zyxx_box">
    <div class="yewutixi">
      <h1 class="zyxx_h1">业务体系</h1>
      <div class="zyxx_jieshao">
        {{ chengren.intro }}
      </div>
    </div>
    <div class="jiaoxueyoushi">
      <h1 class="zyxx_h1_2">教学优势</h1>
      <ul class="zyxx_ul">
        <li v-for="(item, index) in chengren.youshi" :key="item.id">
          <div class="zyxx_div">0{{ index + 1 }}</div>
          <span class="zyxx_span">{{ item.title }}</span>
          <div class="zyxx_div2">
            {{ item.content }}
          </div>
        </li>
      </ul>
    </div>

    <div class="tuobiao">
      <!-- 四个圆 -->
      <div class="zyxx_kechengtixi">
        <h1 class="zyxx_kechengtixi_h1">教学理念</h1>
        <ul class="zyxx_kechengtixi_ul">
          <li v-for="item in chengren.linian" :key="item.id">
            <div class="zyxx_kechengtixi_ul_div">
              {{ item.title }}
            </div>
            <div class="zyxx_kechengtixi_ul_jieshao">
              {{ item.content }}
            </div>
          </li>
        </ul>
      </div>
    </div>

    <div class="zyxx_biyezhengshu">
      <div class="zyxx_biyezhengshu_left">
        <div class="icon">
          <img src="~@/assets/引号.png" alt="" class="img" />
        </div>
        <div class="text">莱芜职业技术学院毕业证书</div>
      </div>
      <ul class="zyxx_biyezhengshu_right">
        <li v-for="(item, index) in chengren.cers" :key="index">
          <img class="img" :src="item" alt="" />
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { business7 } from "@/api/yewutixi";
export default {
  data() {
    return {
      chengren: {},
    };
  },
  created() {
    this.create();
  },
  methods: {
    async create() {
      const chengren = await business7();
      this.chengren = chengren;
      // console.log(chengren);
    },
  },
};
</script>

<style lang="scss" scoped>
.img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
// html {
//   position: relative;
// }
.zyxx_box {
  // width: 1400px;
  // margin: 0 auto;
  .yewutixi {
    width: 1400px;
    margin: 0 auto;
    .zyxx_h1 {
      width: 1400px;
      margin-top: 50px;
      margin-bottom: 50px;
      color: #30384d;
      font-size: 25px;
      font-weight: 600;
      text-align: center;
      position: relative;
      &::after {
        content: " ";
        position: absolute;
        top: 50px;
        left: 50%;
        margin-left: -40px;
        width: 80px;
        height: 5px;
        background-color: #9b0e12;
      }
    }
    .zyxx_jieshao {
      // height: 200px;
      text-indent: 2em;
      margin-bottom: 50px;
      font-size: 20px;
      line-height: 40px;
    }
  }
  .jiaoxueyoushi {
    width: 1400px;
    margin: 0 auto;
    .zyxx_h1_2 {
      margin-top: 50px;
      // 因为下面脱标了  所以高度要给它加上
      margin-bottom: 50px;
      color: #30384d;
      font-size: 25px;
      font-weight: 600;
      text-align: center;
      position: relative;
      &::after {
        content: " ";
        position: absolute;
        top: 50px;
        left: 50%;
        margin-left: -40px;
        width: 80px;
        height: 5px;
        background-color: #9b0e12;
      }
    }
    .zyxx_ul {
      // margin-bottom: 850px;
      width: 100%;
      height: 750px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-content: space-between;
      li {
        width: 690px;
        height: 365px;
        background-color: #fcfafc;
        display: flex;
        flex-direction: column;
        // align-content: center;
        align-items: center;
        justify-content: center;
        .zyxx_div {
          margin-bottom: 35px;
          width: 90px;
          height: 90px;
          background-color: #9b0e12;
          text-align: center;
          line-height: 90px;
          font-size: 33px;
          font-weight: 600;
          color: #fff;
        }
        .zyxx_span {
          margin-bottom: 35px;
          color: #30384d;
          font-size: 19px;
          font-weight: 600;
        }
        .zyxx_div2 {
          width: 600px;
          font-size: 17px;
          color: #30384d;
          line-height: 28px;
          text-align: center;
        }
      }
    }
  }

  .tuobiao {
    position: relative;
    background-color: blue;
    height: 600px;
    .zyxx_kechengtixi {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 600px;
      background-color: #9b0e12;
      .zyxx_kechengtixi_h1 {
        margin-top: 50px;
        // 因为下面脱标了  所以高度要给它加上
        margin-bottom: 50px;
        color: #fff;
        font-size: 25px;
        font-weight: 600;
        text-align: center;
        position: relative;
        &::after {
          content: " ";
          position: absolute;
          top: 50px;
          left: 50%;
          margin-left: -40px;
          width: 80px;
          height: 5px;
          background-color: #fff;
        }
      }
      .zyxx_kechengtixi_ul {
        width: 1400px;
        height: 450px;
        margin: 0 auto;
        display: flex;
        li {
          width: 25%;
          height: 90%;

          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
          .zyxx_kechengtixi_ul_div {
            margin-top: 30px;
            margin-bottom: 40px;
            width: 175px;
            height: 175px;
            border-radius: 50%;
            border: 3px solid #fff;
            text-align: center;
            line-height: 175px;
            color: #fff;
            font-size: 25px;
            font-weight: 600;
          }
          .zyxx_kechengtixi_ul_jieshao {
            flex: 1;
            width: 215px;
            color: #fff;
            text-align: center;
            font-size: 17px;
          }
        }
      }
    }
  }

  .zyxx_biyezhengshu {
    margin: 0 auto;
    margin-top: 50px;
    width: 1400px;
    height: 330px;
    display: flex;
    justify-content: space-between;
    .zyxx_biyezhengshu_left {
      width: 315px;
      background-color: #9b0e12;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      .icon {
        position: absolute;
        top: 70px;
        left: 40px;
        width: 30px;
      }
      .text {
        width: 240px;
        font-size: 30px;
        font-weight: 600;
        color: #fff;
      }
    }
    .zyxx_biyezhengshu_right {
      // flex: ;
      width: 1000px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      li {
        width: 325px;
        height: 220px;
        background-color: #30384d;
          margin-right: 10px;
      }
    }
  }
}
</style>