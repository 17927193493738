<template>
  <div class="spzx_box">
    <div class="spzx_box_top">
      <ul class="spzx_box_top_ul">
        <li
          v-for="item in shipin"
          :key="item.id"
          @click="shipintiaozhuan(item.id)"
        >
          <div class="spzx_box_top_ul_div">
            <!-- <video :src="item.url" class="img"></video> -->
            <img :src="item.image" alt="" class="img" />
          </div>
          <div class="zigezheng">{{ item.title }}</div>
          <div class="time">
            {{ item.createtime }}
          </div>
        </li>
      </ul>
    </div>
    <div class="block">
      <!-- <el-pagination
        style="width: 200px"
        background
        layout="prev, pager, next"
        :total="20"
        :page-size="5"
      >
      </el-pagination> -->
    </div>
  </div>
</template>

<script>
import { newsvideo } from "@/api/xinwenzhongxin";
export default {
  data() {
    return {
      shipin: {},
    };
  },
  created() {
    this.create();
  },
  methods: {
    async create() {
      const shipin = await newsvideo();
      // console.log(shipin, "jituan");
      this.shipin = shipin;
    },
    shipintiaozhuan(id) {
      // console.log(id);
      this.$router.push({
        name: "shipinItem",
        params: {
          id: id,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.spzx_box {
  margin-bottom: -80px;
  .spzx_box_top {
    width: 100%;
    margin-top: 45px;
    .spzx_box_top_ul {
      width: 100%;
      // height: 1230px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      li {
        height: 375px;
        margin-top: 15px;
        width: 455px;
        border: 1px solid #dedede;
        .spzx_box_top_ul_div {
          width: 100%;
          height: 265px;
          background-color: #9b0e12;
        }
        .zigezheng {
          font-size: 25px;
          margin-left: 20px;
          margin-top: 20px;
          font-weight: 600;
        }
        .time {
          margin-left: 20px;
          margin-top: 20px;
          font-size: 15px;
          color: #a1a8b2;
        }
      }
    }
  }

  .block {
    // background-color: red;
    display: flex;
    justify-content: center;
    margin-top: 100px;
    /deep/.el-pager li {
      font-size: 15px;
    }
    /deep/ .btn-prev {
      width: 45px;
      height: 45px;
    }
    /deep/ .number {
      width: 45px;
      height: 45px;
      line-height: 47px;
      text-align: center;
    }
    /deep/ .el-pagination.is-background .el-pager li:not(.disabled):hover {
      color: #9b0e12;
    }
    /deep/.el-pagination.is-background .el-pager li:not(.disabled).active {
      background-color: #9b0e12dd;
      color: #fff;
    }
    /deep/ .btn-next {
      width: 45px;
      height: 45px;
    }
  }
}
</style>