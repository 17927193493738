<template>
  <div class="jtry_box">
    <!-- <h1 class="jtry_box_h1">集团荣誉</h1> -->
    <div class="jtry_box_div">
      {{bigimg.intro}}
    </div>
    <div class="jtry_box_img">
      <img :src="bigimg.image" alt="" class="img" />
    </div>
    <ul class="jtry_box_ul">
      <li v-for="(item, index) in develop" :key="index">
        <div class="jtry_box_li_div">
          <img :src="item.image" alt="" class="img" />
        </div>
        <!-- <div class="jtry_box_li_jieshao">{{item.title}}</div> -->
      </li>
    </ul>
  </div>
</template>

<script>
import { companyhonour, companyhonourb } from "@/api/intoxuemeng";
export default {
  data() {
    return {
      develop: {},
      bigimg: {},
    };
  },
  created() {
    this.create();
  },
  methods: {
    async create() {
      const fazhan = await companyhonour();
      const bigimg = await companyhonourb();
      // console.log(fazhan);
      this.develop = fazhan;
      // console.log(this.develop);
      this.bigimg = bigimg;
      // console.log(this.bigimg);
    },
  },
};
</script>

<style lang="scss" scoped>
.img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.jtry_box {
  width: 100%;
  color: #30394d;
  .jtry_box_h1 {
    margin-top: 50px;
    margin-bottom: 30px;
    text-align: center;
    font-size: 30px;
    font-weight: 600;
  }
  .jtry_box_div {
    width: 755px;
    margin: 0 auto;
     margin-top: 50px;
    text-align: center;
    font-size: 20px;
  }
  .jtry_box_img {
    margin-top: 70px;
    margin-bottom: 55px;
    // height:1000px;
    width: 1400px;
    // background-color: #9b0e12;
  }
  .jtry_box_ul {
    // height: 1070px;

    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-content: space-around;
    li {
      width: 330px;
      margin-bottom: 15px;
      background-color: #fff;
      // border: 1px solid #868784;
      justify-content: space-around;
      .jtry_box_li_div {
        height: 229px;
        // background-color: #9b0e12;
        border-bottom: 1px solid #868784;
      }
      .jtry_box_li_jieshao {
        margin-top: 20px;
        text-align: center;
        font-weight: 600;
        font-size: 20px;
      }
    }
  }
}
</style>